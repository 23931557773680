import axios from "@/axios";
import store from "../index";

const state = {
  decisions: {},
};

const getters = {
  decisionPrefix: () =>
    store.getters["authenticated"].role == "publisher"
      ? "publisher"
      : store.getters["authenticated"].role == "admin"
      ? "admin"
      : "user",
  getAllDecisions: (state) => state.decisions.data,
  getDecisionCurrentPage: (state) => state.decisions.current_page,
  getDecisionLastPage: (state) => state.decisions.last_page,
  getDecisionRecord: (state) => (id) => {
    var decisionRecord = state.decisions.data.find(
      (decisionRecord) => decisionRecord.id === id
    );
    return decisionRecord;
  },
};
const actions = {
  async fetchdecisions({ commit }, info) {
    //check the prefix according to the user type
    const response = await axios.get(
      this.getters["decisionPrefix"] +
        "/documents?per_page=10&page=" +
        info.page +
        "&type=decision&certified=yes&search=" +
        info.keyword +
        "&from=" +
        info.from +
        "&to=" +
        info.to +
        "&is_archived=" +
        info.is_archived
    );
    commit("SET_DECISIONS", response.data);
  },

  async editApprovedDecision({ commit }, info) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post(
      "/publisher/documents/" + info.get("id"),
      info
    );
    commit("SET_EDIT_DECISION_APPROVED_DATA", response.data.data);
  },

  setDecisionCurrentPage({ commit }, currentPage) {
    commit("SET_DECISION_CURRENT_PAGE", currentPage);
  },
  setDecisionLastPage({ commit }, lastPage) {
    commit("SET_DECISION_LAST_PAGE", lastPage);
  },
};

const mutations = {
  SET_DECISIONS: (state, decision) => (state.decisions = decision),
  SET_DECISION_CURRENT_PAGE: (state, data) =>
    (state.decisions.current_page = data),
  SET_DECISION_LAST_PAGE: (state, data) => (state.decisions.last_page = data),

  SET_EDIT_DECISION_APPROVED_DATA: (state, payload) => {
    const i = state.decisions.data.map((item) => item.id).indexOf(payload.id);
    state.decisions.data[i].details = payload.details;
    state.decisions.data[i].subject = payload.subject;
    state.decisions.data[i].file_path = payload.file_path;
    state.decisions.data[i].documentNumber = payload.documentNumber;
    state.decisions.data[i].decisionNumber = payload.decisionNumber;
    state.decisions.data[i].is_certified = payload.is_certified;
    state.decisions.data = state.decisions.data.map((x) => {
      if (x.id === payload.id) {
        return Object.assign({}, x, payload.data);
      }
      return x;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
