<template>
  <div class="user-dashboard">
    <AppBar />
    <Toolbar />
    <v-container fluid >
      <v-row>
        <v-col
          cols="12"
          lg="2"
          sm="2"
          md="2"
          v-show="$vuetify.breakpoint.mdAndUp"
        >
          <AppSideBar />
        </v-col>
        <v-col cols="12" lg="10" sm="12" md="10" class="scroll-y mt-2" >
          <router-view />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AppBar from "../../components/AppBar";
import Toolbar from "../../components/Toolbar";
import AppSideBar from "../../components/AppSideBar";

export default {
  name: "UserDashboard",
  components: {
    AppBar,
    Toolbar,
    AppSideBar,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scopped>
body,
html {
  overflow: hidden;
  height: 100vh;
}

.sidebar-link:visited {
  background-color: #f1f1f1 !important;
}
.user-dashboard {
  display: block;
  width: 100%;
  height: 100%;
  float: left;
  background-color: #f8f8f8;
  .scroll-y {
    overflow-y: auto;
    max-height: 80vh !important;
    padding-bottom: 3rem;
  }
  .scroll-y::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.6);
    background-color: #ffff;
  }

  .scroll-y::-webkit-scrollbar {
    width: 3px;
    background-color: #0f335f;
  }

  .scroll-y::-webkit-scrollbar-thumb {
    background-color: #0f335f;
    background-image: -webkit-linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 25%,
      transparent 100%,
      rgba(0, 0, 0, 1) 75%,
      transparent
    );
  }
}
</style>
