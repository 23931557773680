<template>
  <div id="composeMessageModal">
    <v-dialog
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="small-text ma-2 outlined ps-2"
          elevation="0"
          v-if="authenticated.role == 'publisher'"
        >
          <v-icon right dark class="mx-1">
            mdi-file-plus
          </v-icon>
          اضافة ملفات للأطلاع
        </v-btn>
      </template>
      <v-card v-click-outside="handleClickOutside" dir="rtl">
        <v-card-title class="small-text">
          <v-icon small class="ms-1 me-1">
            mdi-file-plus-outline
          </v-icon>
          ملف للأطلاع جديد
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="addNewNotesForm" v-model="valid" lazy-validation>
              <v-row class="">
                <v-col cols="12" lg="8" md="8">
                  <v-text-field
                    outlined
                    label="الموضوع"
                    hide-details="auto"
                    v-model="subject"
                    class="text-right small-text"
                    :rules="requiredRule"
                    dense
                  ></v-text-field>
                  <v-menu
                    v-model="datePickerMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="doc_date"
                        label="التاريخ"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        outlined
                        dense
                        :rules="requiredRule"
                        class="small-text mt-5"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="doc_date"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>

                  <v-textarea
                    outlined
                    label=" تفاصيل "
                    value=""
                    :rules="requiredRule"
                    v-model="details"
                    class="small-text custom-textarea"
                    rows="2"
                  ></v-textarea>

                  <v-file-input
                    outlined
                    class="titleaddress small-text"
                    accept="application/pdf"
                    label="إرفاق الملفات"
                    show-size
                    dense
                    multiple
                    id="braodcast_attachment"
                    v-model="Attachments"
                    :rules="requiredRule"
                  ></v-file-input>
                </v-col>

                <v-col cols="12" md="4" class="ps-6 pe-6">
                  <h4 class=" pt-1 pb-1 mb-3">
                    المشاهدين للملف
                  </h4>
                  <hr />
                  <v-checkbox
                    v-model="allSelected"
                    label="تحديد الكل"
                    class=" small-text"
                    color="indigo darken-3"
                    @click="selectAll()"
                  ></v-checkbox>
                  <v-row>
                    <v-col
                      v-for="(item, i) in getAllDecisionViewers"
                      :key="i"
                      cols="12"
                      md="12"
                      class="voter-container mt-4 me-2 ms-2 pa-0"
                    >
                      <v-checkbox
                        :rules="requiredRule2"
                        v-model="decisionViewers"
                        :label="item.name"
                        :value="item.id"
                        class="ps-3 pe-3 pt-0 pb-0 small-text"
                        color="indigo darken-3"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-alert
                  dense
                  text
                  type="success"
                  class="mt-3"
                  v-if="ComplaintSuccessAlert"
                >
                  عملية تسجيل البيانات <strong>تمت بنجاح</strong>
                </v-alert>
                <v-alert
                  dense
                  outlined
                  type="error"
                  class="mt-3"
                  v-if="ComplaintErrorAlert"
                >
                  فشل في عملية التسجيل الرجاء اعادة المحاولة
                </v-alert>
              </v-row>

              <v-row class="d-flex justify-center align-center">
                <v-col cols="12" lg="3" sm="12">
                  <v-card-actions>
                    <v-btn
                      :loading="loadingSpinner"
                      :disabled="loadingSpinner"
                      class="send-btn ps-2 pe-4 small-text d-block"
                      @click="addInformation"
                      elevation="0"
                      ><v-icon small class="pe-1 ps-1 small-text"
                        >mdi-file-plus-outline</v-icon
                      >
                      تسجيل ملف أطلاع جديد
                    </v-btn>
                    <v-btn
                      color="darken-1"
                      class="outlined small-text d-block"
                      text
                      @click="closeDialog"
                      >إلغاء</v-btn
                    >
                  </v-card-actions></v-col
                >
              </v-row>
              <v-row dir="ltr" class="d-flex justify-center align-center">
                <v-col cols="12" lg="8" md="8" class="pt-0 text-left">
                  <v-card class="pa-4 text-left" elevation="0">
                    <v-row dir="ltr">
                      <v-col
                        cols="4"
                        sm="4"
                        class="pa-0 pt-2 ps-0 pe-0 text-center"
                        v-show="$vuetify.breakpoint.mdAndUp"
                      >
                        <img
                          style="vertical-align:middle;"
                          class="ps-1 pe-1 mt-1"
                          width="auto"
                          height="70px"
                          src="../../../assets/imgs/BlueCMC.svg"
                          alt
                        />
                        <img
                          style="vertical-align:middle;"
                          class="ps-1 pe-2 mt-1"
                          width="auto"
                          height="60px"
                          src="../../../assets/imgs/inlayer-design.svg"
                          alt
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        lg="8"
                        class=" text-left pe-0 pt-2 pt-3"
                      >
                        <span class="d-block eng-text blue-text"> </span>
                        <span class="d-block eng-text blue-text">
                          Electronic Mail System V.2.0
                        </span>
                        <span class="d-block eng-text blue-text comment-text">
                          Department of programming | IT Regulation directorate
                        </span>
                        <span class="d-block eng-text blue-text comment-text">
                          &copy; The Communication and Media Commission of Iraq
                          (CMC)
                        </span>
                        <span class="d-block eng-text blue-text comment-text">
                          <v-spacer></v-spacer>
                          Version:2.0 / Production
                          {{ new Date().getFullYear() }} / SYS-ID:85
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- 
    <v-snackbar
      :timeout="-1"
      bottom
      color="deep-purple accent-4"
      elevation="24"
      v-model="snackbar"
    >
      {{ alertText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "composeMessageModal",
  components: {},
  computed: {
    ...mapGetters(["getAllDecisionViewers", "authenticated"]),
  },
  created() {
    //get directories
    this.fetchDecisionViewers();

    //this.getReceiverDetails();
  },
  data() {
    return {
      loadingSpinner: false,
      datePickerMenu: false,
      receiver_list: [],
      reciever_id: [],
      subject: null,
      doc_date: null,
      details: null,
      Attachments: [],
      ComplaintSuccessAlert: false,
      ComplaintErrorAlert: false,
      alertText: null,
      snackbar: false,
      loader: null,
      loading3: false,
      valid: true,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      requiredRule2: [(v) => v.length > 0 || "هذا الحقل مطلوب"],
      NewInformationForm: new FormData(),
      dialog: false,
      decisionViewers: [],
      allSelected: false,
    };
  },

  methods: {
    ...mapActions(["fetchDecisionViewers", "addNewInformation"]),
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.dialog = false;
    },

    textFormat(item) {
      if (item.name) {
        if (item.userdirectory) return item.name;
        else return item.name;
      }
    },

    selectAll() {
      this.decisionViewers = [];
      if (this.allSelected) {
        for (var user in this.getAllDecisionViewers) {
          this.decisionViewers.push(this.getAllDecisionViewers[user].id);
        }
      }
    },

    addInformation() {
      this.NewInformationForm = new FormData();
      if (!this.$refs.addNewNotesForm.validate()) return;
      this.loadingSpinner = true;
      this.NewInformationForm.append("subject", this.subject);
      this.NewInformationForm.append("type", "informations");
      this.NewInformationForm.append("docDate", this.doc_date);
      this.NewInformationForm.append("details", this.details);
      this.loader = "loading3";

      for (let i = 0; i < this.decisionViewers.length; i++) {
        this.NewInformationForm.append(
          "receivers[" + i + "]",
          this.decisionViewers[i]
        );
      }
      for (let i = 0; i < this.Attachments.length; i++) {
        this.NewInformationForm.append("file[" + i + "]", this.Attachments[i]);
      }
      this.addNewInformation(this.NewInformationForm)
        .then(() => {
          this.loadingSpinner = false;
          this.ComplaintSuccessAlert = true;
          setTimeout(() => {
            this.dialog = false;
            this.alertText = null;
            this.snackbar = false;
            this.loader = null;
            this.loading3 = false;
            this.valid = false;
            this.receiver_list = [];
            this.reciever_id = [];
            this.message_detail = "";
            this.notes = "";
            this.fileIds = [];
            this.NewInformationForm = new FormData();
            this.ComplaintSuccessAlert = false;
            this.ComplaintErrorAlert = false;
            this.$refs.addNewNotesForm.reset();
          }, 3000);
        })
        .catch(() => {
          this.loadingSpinner = false;
          this.ComplaintErrorAlert = true;
          this.ComplaintSuccessAlert = false;
          setTimeout(() => {
            this.ComplaintErrorAlert = false;
          }, 3000);
        });
    },
  },
};
</script>

<style scoped>
* {
  font-weight: bold !important;
}

.send-btn {
  background-color: #0f335f !important;
  color: #f1f1f1 !important;
}

.textfields {
  max-height: 0px;
  vertical-align: middle;
}

.titleaddress {
  font-size: small;
  color: black;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  height: 25px;
}

h2 {
  text-align: center;
}

.outlined {
  background: transparent !important;
  border: 2px solid #0f335f;
}

.voters-title {
  background-color: #f1f1f1 !important;
  color: #0f335f;
  border-radius: 5px;
}

.voter-container {
  background-color: #f1f1f1;
  color: #0f335f;
  border-radius: 5px;
}
</style>
