import axios from "@/axios";
import store from "../index";

const state = {
  sentMails: {},
  decryptedFile: "",
};

const getters = {
  SentMailPrefix: () =>
    store.getters["authenticated"].role == "publisher"
      ? "publisher"
      : store.getters["authenticated"].role == "admin"
      ? "admin"
      : "user",

  allSentMail: (state) => state.sentMails.data,
  getSentMail: (state) => (id) => {
    var sentMail = state.sentMails.data.find((sentMail) => sentMail.id === id);
    return sentMail;
  },
  getSentMailCurrentPage: (state) => state.sentMails.current_page,
  getSentMailLastPage: (state) => state.sentMails.last_page,
  getDecryptedFileSend: (state) => state.decryptedFile,
};
const actions = {
  async fetchSentMail({ commit }, info) {
    const response = await axios.get(
      this.getters["SentMailPrefix"] +
        "/sent?per_page=10&page=" +
        info.page +
        "&keyword=" +
        info.keyword +
        "&from=" +
        info.from +
        "&to=" +
        info.to
    );

    commit("SET_SENT_MAILS", response.data);
  },

  /*
  async searchByKeywordSend({ commit }, keyword) {
    const response = await axios.post("/user/emails/search/send", { keyword });
    commit("SET_SEARCH_DATA", response.data);
    return response.data;
  }, 
  */
  async decryptFile({ commit }, file) {
    axios.defaults.headers.post["responseType"] = "multipart/blob";

    const response = await axios.post("/auth/getFile?filePath=" + file);
    commit("SET_DECRYPTED_FILE_SENT", response.data);
  },
  setSentMailCurrentPage({ commit }, currentPage) {
    commit("SET_SENT_MAIL_CURRENT_PAGE", currentPage);
  },
};
const mutations = {
  SET_SENT_MAILS: (state, data) => (state.sentMails = data),
  SET_SENT_MAIL_CURRENT_PAGE: (state, data) =>
    (state.sentMails.current_page = data),
  SET_DECRYPTED_FILE_SENT: (state, data) => (state.decryptedFile = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
