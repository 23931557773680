import axios from "@/axios";
const state = {
  departments: {}
};

const getters = {
  allDepartments: state => state.departments.data
};
const actions = {
  async fetchDepartments({ commit }) {
    const response = await axios.get("/department");
    commit("SET_DEPARTMENTS", response.data);
  }
};
const mutations = {
  SET_DEPARTMENT: (state, department) => (state.departments = department)
};

export default {
  state,
  getters,
  actions,
  mutations
};
