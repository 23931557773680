<template>
  <div>
    <v-dialog
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :value="workScheduleDetailsDialog"
      v-if="details"
    >
      <v-card v-click-outside="handleClickOutside" dir="rtl">
        <v-container class="pt-5">
          <v-row>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-row class="mt-1 ">
                <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 ps-2 pe-2">
                  <v-btn
                    color="darken-1"
                    class="outlined mt-2  ms-1 me-1 "
                    text
                    @click="closeDialog"
                  >
                    <v-icon small class="ms-1 me-1"
                      >mdi-arrow-right-drop-circle-outline</v-icon
                    >
                    رجوع</v-btn
                  >
                </v-col>
              </v-row>

              <v-col cols="12"
                ><span class=" me-1 ms-1">
                  تفاصيل جدول الاعمال
                </span></v-col
              >

              <!-- decision details -->
              <v-col cols="12" sm="12" md="12" lg="12" class="ps-0 pe-0">
                <div class="custom-division pa-4">
                  <span class="mt-2 pb-2 d-block small-text">الموضوع</span>

                  <span class="eng-text d-block small-text">
                    {{ details.subject }}
                  </span>
                </div>
                <div class="custom-division-2 pa-4">
                  <span class="mt-2 pb-2 d-block small-text">التاريخ</span>

                  <span class="eng-text d-block small-text">
                    {{ details.doc_date }}
                  </span>
                </div>

                <div class="custom-division pa-4">
                  <span class="mt-2 pb-2 d-block small-text"
                    >تفاصيل جدول الاعمال</span
                  >

                  <span class=" d-block small-text ">
                    {{ details.details }}
                  </span>
                </div>
              </v-col>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="file-section">
              <div class=" pa-4" v-if="details.file_path.length != 0">
                <span class=" d-block small-text ">
                  الملف المرفق
                </span>

                <span class=" d-block small-text ">
                  يرجى تحميل الملف المرفق لكي يتم استعراض تفاصيل الملف
                </span>

                <v-btn
                  :loading="loadingSpinner"
                  :disabled="showFileStatus"
                  class="send-btn ps-2 pe-4 mt-3 small-text"
                  @click="showFile(details.file_path)"
                  elevation="0"
                  ><v-icon small class="pe-1 ps-1">mdi-file-pdf-box</v-icon>
                  تحميل ملف جدول الاعمال
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <!-- File details -->
        </v-container>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>

    <WorkScheduleEdit
      :id="workScheduleEditId"
      :workScheduleEditDialog="workScheduleEditDialog"
      :details="workScheduleEditInfo"
      @workScheduleEditDialogClosed="workScheduleEditDialog = $event"
    />
  </div>
</template>
<script>
import WorkScheduleEdit from "../../../../components/Models/Decisions/WorkSchedule/EditWorkSchedule";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["workScheduleDetailsDialog", "details"],
  components: {
    WorkScheduleEdit,
  },
  data() {
    return {
      overlay: false,
      showFileStatus: false,
      dialog: false,
      colorParameter: "",
      loadingSpinner: false,
      workScheduleEditId: 0,
      workScheduleEditDialog: false,
      workScheduleEditInfo: {},
    };
  },
  computed: {
    ...mapGetters(["getDecryptedFile"]),
  },
  created() {},
  methods: {
    ...mapActions(["decryptFile"]),
    showFile(files) {
      console.log(this.details);
      this.showFileStatus = true;
      this.overlay = true;
      files.forEach((file) => {
        this.decryptFile(file)
          .then(() => {
            this.openBase64NewTab(this.getDecryptedFile);
            this.overlay = false;
            this.showFileStatus = false;
          })
          .catch(() => {
            this.overlay = false;
            this.showFileStatus = false;
          });
      });
    },

    closeDialog() {
      this.$emit("detailsWorkScheduleDialogClosed", false);
    },
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") {
        this.closeDialog();
      }
    },

    editModal() {
      this.workScheduleEditInfo = this.details;
      this.workScheduleEditDialog = true;
    },
  },
};
</script>

<style scoped>
.custom-division {
  background: #f5f5f5 !important;
  color: #0f335f;
  border-radius: 2px;
  border: 1px solid #f5f5f5 !important;
}

.custom-division-2 {
  background: #eeeeee !important;
  color: #0f335f;
  border-radius: 2px;
  border: 1px solid #eeeeee !important;
}
.custom-division-3 {
  background: #fafafa !important;
  color: #0f335f;
  border-radius: 2px;
  border: 1px solid #cfd8dc !important;
}

.send-btn {
  background-color: #0f335f !important;
  color: #f1f1f1 !important;
}

.outlined {
  background: transparent !important;
  border: 1px solid #0f335f;
  color: #0f335f !important;
}
</style>
