import axios from "@/axios";
import store from "../index";

const state = {
  inboxMail: {},
  emailReceivers: [],
  decryptedFile: "",
};

const getters = {
  inboxMailPrefix: () =>
    store.getters["authenticated"].role == "publisher"
      ? "publisher"
      : store.getters["authenticated"].role == "admin"
      ? "admin"
      : "user",
  allInboxMail: (state) => state.inboxMail.data,
  getInbox: (state) => (id) => {
    var inbox = state.inboxMail.data.find((inbox) => inbox.id === id);
    return inbox;
  },
  getInboxCurrentPage: (state) => state.inboxMail.current_page,
  getInboxLastPage: (state) => state.inboxMail.last_page,
  //receivers
  allEmailReceivers: (state) => state.emailReceivers,
  getDecryptedFile: (state) => state.decryptedFile,
};
const actions = {
  async fetchInboxMails({ commit }, info) {
    /*    var searchStr = "";
    var keyword = "";
    if (info.from != null & info.into != null) {
      searchStr = "&from=" + info.from + "&to=" + info.into;
    }

    if (info.keyword != null) {
      keyword = "&keyword=" + info.keyword;
    } */
    const response = await axios.get(
      this.getters["inboxMailPrefix"] +
        "/inbox?per_page=10&page=" +
        info.page +
        "&keyword=" +
        info.keyword +
        "&from=" +
        info.from +
        "&to=" +
        info.to
    );
    commit("SET_INBOX_MAILS", response.data);
  },
  async changeStatus({ commit }, id) {
    const response = await axios.post(
      this.getters["inboxMailPrefix"] + "/seen/emails/" + id
    );
    if (response.data.message == "updated") {
      commit("CHANGE_STATUS", id);
      return "updated";
    } else return "failed";
  },
  async fetchEmailReceivers({ commit }) {
    const response = await axios.get(
      this.getters["inboxMailPrefix"] + "/getEmailReceivers"
    );
    commit("SET_EMAIL_RECEIVERS", response.data);
  },
  setInboxCurrentPage({ commit }, currentPage) {
    commit("SET_INBOX_CURRENT_PAGE", currentPage);
  },

  async addNewMail({ commit }, Mail) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post(
      this.getters["inboxMailPrefix"] + "/createNewEmail",
      Mail
    );
    if (response.status == 201) {
      commit("CREATE_NEW_MAIL", response.data);
    }
  },

  async decryptFile({ commit }, file) {
    axios.defaults.headers.post["responseType"] = "multipart/blob";

    const response = await axios.post("/auth/getFile?filePath=" + file);
    commit("SET_DECRYPTED_FILE", response.data);
  },
};
const mutations = {
  SET_INBOX_MAILS: (state, data) => (state.inboxMail = data),
  CREATE_NEW_MAIL: (state, data) => (state.inboxMail = data),
  SET_INBOX_CURRENT_PAGE: (state, data) =>
    (state.inboxMail.current_page = data),

  SET_EMAIL_RECEIVERS: (state, data) => (state.emailReceivers = data),

  CHANGE_STATUS: (state, id) => {
    const i = state.inboxMail.data.map((item) => item.id).indexOf(id);
    state.inboxMail.data[i].pivot.seen = 1;
  },
  SET_DECRYPTED_FILE: (state, data) => (state.decryptedFile = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
