import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import UserDashboard from "../views/user/UserDashboard.vue";
import MainPage from "../views/user/MainPage.vue";
import InboxPage from "../views/user/InboxPage.vue";
import SentMail from "../views/user/SentMail.vue";
import Decisions from "../views/user/Decisions.vue";
import MainDecision from "../components/Models/Decisions/Main.vue";
import ApprovedDecisions from "../components/Models/Decisions/MainDecisions.vue";
import NewDecisions from "../components/Models/Decisions/DecisionsProject.vue";
import WorkSchedule from "../components/Models/Decisions/WorkSchedule/WorkSchedule.vue";
import ArchiveWorkSchedule from "../components/Models/Decisions/WorkSchedule/ArchiveWorkSchedule.vue";

import PublicView from "../views/public/DocumentView.vue";

import Notes from "../views/user/NotesFiles.vue";
import Report from "../views/user/Report.vue";
import MainNotes from "../components/Models/Notes/Main.vue";
import UserInfo from "../views/UserInfo.vue";
import store from "../store/index";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/public-view",
    name: "Public",
    component: PublicView,
  },
  {
    path: "/user/dashboard",
    name: "UserDashboard",
    component: UserDashboard,
    redirect: "/user/dashboard/main",
    meta: { requiresAuth: true },
    children: [
      {
        path: "main",
        name: "MainPage",
        component: MainPage,
      },
      {
        path: "inbox",
        name: "InboxPage",
        component: InboxPage,
      },
      {
        path: "sent",
        name: "SentMail",
        component: SentMail,
      },
      {
        path: "report",
        name: "Report",
        component: Report,
      },
      {
        path: "decisions",
        name: "DecisionsMade",
        component: Decisions,
        redirect: "/user/dashboard/decisions/main-decision",
        children: [
          {
            path: "main-decision",
            name: "main-decision",
            component: MainDecision,
          },
          {
            path: "approved-decisions",
            name: "approved-decision",
            component: ApprovedDecisions,
          },
          {
            path: "new-decisions",
            name: "new-decision",
            component: NewDecisions,
          },
          {
            path: "work-schedule",
            name: "work-schedule",
            component: WorkSchedule,
          },
          {
            path: "archive-work-schedule",
            name: "archive-work-schedule",
            component: ArchiveWorkSchedule,
          },
        ],
      },

      {
        path: "notes",
        name: "NotesFiles",
        component: Notes,
        redirect: "/user/dashboard/notes/main-notes",
        children: [
          {
            path: "main-notes",
            name: "main-notes",
            component: MainNotes,
          },
        ],
      },
      {
        path: "userinfo",
        name: "UserInfo",
        component: UserInfo,
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.getters["isLoggedIn"]
  ) {
    return next({
      name: "Login",
    });
  }
  return next();
});
export default router;
