<template>
  <div class="sent-mails">
    <v-card class="pb-3 custom-data-table" elevation="0">
      <v-card-title>
        <v-row dense>
          <v-col cols="10" lg="9" md="9" sm="9" class="pt-3">
            <v-text-field
              dense
              justify-center
              v-model="Info.keyword"
              label="بحث"
              single-line
              hide-details
              outlined
              class="small-text"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="3" class="text-right">
            <v-btn @click="showAllSendData()" icon color="blue">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn
              :loading="loading3"
              :disabled="loading3"
              elevation="0"
              color="#0f335f"
              class="small-text  ma-2 white--text"
              @click="searchMediaData()"
            >
              <v-icon left dark>
                mdi-magnify
              </v-icon>
              بحث
            </v-btn>

            <v-btn
              class="small-text ma-2 outlined"
              elevation="0"
              @click="showAdvancedSearchDialog()"
            >
              <v-icon right dark class="mx-1">
                mdi-magnify-plus-outline
              </v-icon>
              بحث متقدم
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        align-center
        :headers="headers"
        :items="allSentMail"
        hide-default-footer
        fixed-header
        dir="rtl"
      >
        <template v-slot:item.emailreceivers="{ item }">
          <span
            class="reciever-span ps-2 pe-2"
            v-for="email of item.emailreceivers"
            :key="email.id"
            >{{ email.name }}
          </span>
        </template>
        <template v-slot:item.showdata="{ item }">
          <v-btn
            small
            elevation="0"
            class="showFileBtn"
            v-if="item.emailfiles.length != 0"
            @click="showdata(item.emailfiles)"
          >
            <v-icon small class="me-1 ">mdi-file-pdf-box</v-icon> الملف المرفق
          </v-btn>
        </template>
      </v-data-table>
      <!-- pagination -->
      <v-pagination
        v-model="currentGet"
        :length="lastGet"
        total-visible="6"
        color="#0f335f"
        elevation="0"
        square
        :disabled="disablePagination"
      ></v-pagination>
      <!-- pagination -->
    </v-card>

    <DecisionSearch
      :decisionSearchDialog="decisionSearchDialog"
      @decisionSearchDialogClosed="decisionSearchDialog = $event"
      @searchDateChanged="handleSearchByDate($event)"
    />
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DecisionSearch from "../../components/Models/AdvancedSearch";

export default {
  name: "SentMail",
  watch: {
    currentGet(newVal) {
      this.Info.page = newVal;
      this.disablePagination = true;
      this.fetchSentMail(this.Info)
        .then(() => {
          this.disablePagination = false;
        })
        .catch(() => {
          this.disablePagination = false;
        });
    },
  },

  created() {
    this.Info.page = 1;
    this.disablePagination = true;

    this.fetchSentMail(this.Info)
      .then(() => {
        this.disablePagination = false;
      })
      .catch(() => {
        this.disablePagination = false;
      });
  },
  computed: {
    ...mapGetters(["allSentMail", "getDecryptedFileSend"]),

    currentGet: {
      get() {
        return this.$store.getters.getSentMailCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setSentMailCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getSentMailLastPage;
      },
    },
  },
  components: {
    DecisionSearch,
  },
  data() {
    return {
      showFileStatus: false,
      overlay: false,
      disablePagination: false,
      loading3: false,
      Info: { page: 1, keyword: null, from: null, to: null },
      headers: [
        { text: "الموضوع", value: "subject" },
        { text: "العدد", value: "number" },
        { text: "التفاصيل", value: "body" },
        { text: "وجهة البريد", value: "emailreceivers" },
        { text: "التاريخ", value: "date" },
        { text: "تاريخ الارسال", value: "created_at" },
        { text: "الملاحظات", value: "notes" },
        { text: "عرض", value: "showdata" },
      ],
      perPage: [10, 50, 100, 200, 300, 600, 1200],
      selectedPage: 1,
      decisionSearchDialog: false,
    };
  },

  methods: {
    ...mapActions(["fetchSentMail", "decryptFile"]),

    showAllSendData() {
      this.Info.page = 1;
      this.disablePagination = true;
      this.Info.from = null;
      this.Info.to = null;
      this.Info.keyword = null;
      this.fetchSentMail(this.Info)
        .then(() => {
          this.disablePagination = false;
        })
        .catch(() => {
          this.disablePagination = false;
        });
    },

    handleSearchByDate(event) {
      this.Info.from = event.from;
      this.Info.to = event.to;
      this.Info.keyword = null;

      if (this.selectedPage == 1) {
        this.Info.page = 1;
        this.disablePagination = true;
        this.fetchSentMail(this.Info)
          .then(() => {
            this.disablePagination = false;
          })
          .catch(() => {
            this.disablePagination = false;
          });
      }
    },
    showAdvancedSearchDialog() {
      this.decisionSearchDialog = true;
    },
    showdata(files) {
      this.showFileStatus = true;
      this.overlay = true;
      files.forEach((file) => {
        this.decryptFile(file.path)
          .then(() => {
            this.openBase64NewTab(this.getDecryptedFileSend);
            this.overlay = false;
            this.showFileStatus = false;
          })
          .catch(() => {
            this.overlay = false;
            this.showFileStatus = false;
          });
      });
    },

    searchMediaData() {
      this.Info.page = 1;
      this.disablePagination = true;
      this.fetchSentMail(this.Info)
        .then(() => {
          this.disablePagination = false;
        })
        .catch(() => {
          this.disablePagination = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.inboxes {
  direction: rtl;
  font-family: "JF Flat";
  font-size: large;
  overflow-x: hidden;
}

h2 {
  text-align: center;
}

.titleaddress {
  font-size: medium;
  color: black;
  margin: 0px;
  padding: 0;
  vertical-align: middle;
  height: 25px;
}

.showFileBtn {
  background-color: transparent !important;
  color: #0f335f !important;
  border-radius: 15px;
  border: 1px solid #0f335f;
  font-weight: 600;
}

.reciever-span {
  background-color: #f5f5f5 !important;
  border-radius: 15px;
  font-weight: normal;
}
</style>
