<template>
  <div>
    <v-list dense right class="mt-0 transparent" :dir="direction">
      <v-list-item-group v-model="selectedItem">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          light
          :class="
            item.route === $route.path
              ? 'mb-2 hovered-white undecoratedLink highlighted'
              : 'mb-2 hovered-white undecoratedLink '
          "
          @click="routeLink(item.route, item.linkName)"
        >
          <v-icon light v-text="item.icon" small></v-icon>
          <v-list-item-content :align="alignmentVar">
            <v-list-item-title
              v-text="item.title"
              :align="alignmentVar"
              class="ps-2 pe-2 d-none d-md-block"
            ></v-list-item-title>
          </v-list-item-content>
          <!--      <v-list-item-icon></v-list-item-icon>    @click="assignPattern(item.title)"-->
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-col
      cols="12"
      class="pa-0 pt-2 ps-0 pe-0 text-center"
      v-show="$vuetify.breakpoint.mdAndUp"
    >
      <img
        style="vertical-align:middle;"
        class="ps-1 pe-1 mt-1"
        width="auto"
        height="70px"
        src="../assets/imgs/BlueCMC.svg"
        alt
      />
      <img
        style="vertical-align:middle;"
        class="ps-1 pe-2 mt-1"
        width="auto"
        height="60px"
        src="../assets/imgs/inlayer-design.svg"
        alt
      />
    </v-col>
    <v-col cols="12" class="text-left" dir="ltr">
      <span class="d-block eng-text blue-text comment-text">
        Electronic Mail System V.2.0
      </span>
      <span class="d-block eng-text blue-text comment-text">
        Department of programming | IT Regulation directorate
      </span>
      <span
        v-if="$vuetify.breakpoint.mdAndUp"
        class="d-block eng-text blue-text comment-text"
      >
        &copy; The Communication and Media Commission of Iraq (CMC)
      </span>
      <span
        v-if="$vuetify.breakpoint.mdAndUp"
        class="d-block eng-text blue-text comment-text"
      >
        <v-spacer></v-spacer>
        Version:2.1 / Production
        {{ new Date().getFullYear() }} / SYS-ID:85
      </span>
    </v-col>
  </div>
</template>
<style lang="scss" scopped>
.list-item-style {
  font-size: 14px !important;
  font-weight: normal !important;
}
.undecoratedLink {
  text-decoration: none;
  border-bottom: none;
  background-color: transparent !important;
}
.hovered-white {
  color: #78909c !important;
  background-color: transparent !important;
}

.hovered-white:hover {
  transition: 0.1s !important;
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AppSideBar",
  role: "",

  data() {
    const lang = localStorage.getItem("lang") || "ar";

    return {
      drawer: true,
      direction: lang == "ar" ? "rtl" : "ltr",
      alignmentVar: lang == "ar" ? "right" : "left",
      reverseOption: lang == "ar" ? true : false,
      selectedItem: 0,
      items: [],
      mini: false,
      role: "user",
    };
  },

  computed: {
    ...mapGetters(["authenticated"]),
  },

  created() {
    this.role = this.authenticated.role;
    this.roleCheck(this.role);
  },
  methods: {
    ...mapActions(["signout"]),

    routeLink(route, linkName) {
      if (route == "/") {
        this.signout()
          .then(() => {
            this.$router.push(route);
            localStorage.clear();
          })
          .catch(() => {
            this.$router.push(route);
          });
      } else {
        this.$globalLink = linkName;
        this.$cookies.set("link", linkName); //return this
        Event.$emit("pathChanger", linkName);
        this.$router.push(route);
      }
    },
    roleCheck(role) {
      if (role == "client" || role == "publisher") {
        this.items = [
          {
            title: "الصفحة الرئيسية",
            icon: "mdi-home-outline",
            route: "/user/dashboard/main",
            linkName: "الصفحة الرئيسية",
          },
          {
            title: "صندوق البريد",
            icon: "mdi-email-outline",
            route: "/user/dashboard/inbox",
            linkName: "صندوق البريد",
          },
          {
            title: "البريد المرسل",
            icon: "mdi-send-outline",
            route: "/user/dashboard/sent",
            linkName: "البريد المرسل",
          },
          {
            title: "القرارات وجدول الاعمال",
            icon: "mdi-chart-box-outline",
            route: "/user/dashboard/decisions/main-decision",
            linkName: "القرارات وجدول الأعمال",
          },
          {
            title: "ملفات للأطلاع",
            icon: "mdi-archive-outline",
            route: "/user/dashboard/notes/main-notes",
            linkName: "ملفات الأطلاع",
          },
          {
            title: "الاحصائيات",
            icon: "mdi-chart-box-outline",
            route: "/user/dashboard/report",
            linkName: "الاحصائيات",
          },

          {
            title: "خيارات المستخدم",
            icon: "mdi-account-circle-outline",
            route: "/user/dashboard/userinfo",
            linkName: "خيارات المستخدم",
          },

          {
            title: "تسجيل الخروج",
            icon: "mdi-logout",
            route: "/",
            linkName: "تسجيل الخروج",
          },
        ];
      } else {
        this.items = [];
      }
    },
  },
};
</script>
<style scoped>
.undecoratedLink {
  text-decoration: none;
  border-bottom: none;
  background-color: transparent !important;
}
.hovered-white {
  color: #78909c !important;
  background-color: transparent !important;
}

.highlighted {
  background-color: #f1f1f1 !important;
  color: #0f335f !important;
}
</style>
