<template>
  <div dir="rtl" class="overflowed-div">
    <v-col cols="12" class="pa-1s" v-if="dataAvailabilityStatus == true">
      <h5 class="text-center  arabic-text">جمهورية العراق</h5>
      <h5 class="text-center arabic-text">هيئة الأعلام والأتصالات</h5>
      <h5 class="text-center arabic-text">مجلس المفوضين</h5>
    </v-col>
    <v-container>
      <v-card elevation="0" class="" v-if="dataAvailabilityStatus == false">
        <v-row>
          <v-col cols="12" md="4"></v-col>
          <v-col cols="12" md="4">
            <v-card-text class="pa-1 eng-text text-center">
              The data is not available</v-card-text
            >
            <img
              style="vertical-align:middle;"
              class="ps-1 pe-1 mt-1"
              width="auto"
              height="100%"
              src="../../assets/imgs/error404.svg"
              alt
          /></v-col>
          <v-col cols="12" md="4"></v-col>
        </v-row>
      </v-card>
      <v-card class="pa-4" v-if="dataAvailabilityStatus == true">
        <v-row class="pb-4">
          <v-col cols="12" md="2">
            <v-card-text class="pa-1 arabic-text"> رقم القرار </v-card-text>
            <v-card-text class="pa-1 arabic-text">
              {{ informationGetter.decisionNumber }}
            </v-card-text>
          </v-col>
          <v-col cols="12" md="2">
            <v-card-text class="pa-1 arabic-text">
              التاريخ
            </v-card-text>
            <v-card-text class="pa-1 arabic-text">
              {{ informationGetter.doc_date }}
            </v-card-text>
          </v-col>
          <v-col cols="12" md="4">
            <v-card-text class="pa-1 arabic-text">
              الموضوع
            </v-card-text>
            <v-card-text class="pa-1 arabic-text">
              {{ informationGetter.subject }}
            </v-card-text>
          </v-col>

          <v-col cols="12" md="4" class="text-left pa-4">
            <v-card-text class="pa-0 eng-text"> Document Code</v-card-text>
            <v-card-text class="pa-0 eng-text">
              {{ informationGetter.uuid }}
            </v-card-text></v-col
          >

          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <div class="overflowed">
          <v-card-text class="text-center arabic-text pa-1">
            نص القرار
          </v-card-text>
          <v-card-text class="text-center arabic-text pa-1">
            {{ informationGetter.details }}</v-card-text
          >
        </div>
      </v-card>
    </v-container>

    <v-container>
      <v-row class="justify-center align-center">
        <v-col cols="12" lg="12" sm="12" class="text-left">
          <v-card class="justify-center align-center" elevation="0">
            <v-col cols="12" sm="12" class="pa-0 pt-2 ps-0 pe-0 text-center ">
              <img
                style="vertical-align:middle;"
                class="ps-1 pe-1 mt-1"
                width="auto"
                height="70px"
                src="../../assets/imgs/BlueCMC.svg"
                alt
              />
              <img
                style="vertical-align:middle;"
                class="ps-1 pe-2 mt-1"
                width="auto"
                height="60px"
                src="../../assets/imgs/inlayer-design.svg"
                alt
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              lg="12"
              class=" text-center pe-0 pt-2 pt-3"
            >
              <span class="d-block eng-text blue-text"> </span>
              <span class="d-block eng-text blue-text">
                Electronic Mail System V.2.0
              </span>
              <span class="d-block eng-text blue-text comment-text">
                Department of programming | IT Regulation directorate
              </span>
              <span class="d-block eng-text blue-text comment-text">
                &copy; The Communication and Media Commission of Iraq (CMC)
              </span>
              <span class="d-block eng-text blue-text comment-text">
                <v-spacer></v-spacer>
                Version:2.0 / Production
                {{ new Date().getFullYear() }} / SYS-ID:85
              </span>
            </v-col>
          </v-card>
        </v-col>
      </v-row></v-container
    >
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "userInfo",

  data() {
    return {
      dataAvailabilityStatus: false,
    };
  },

  computed: {
    ...mapGetters(["informationGetter"]),
  },
  methods: {
    ...mapActions(["fetchDocumentInfoUUID"]),

    getDataBasedUUID() {},
  },
  created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const uuid = urlParams.get("uuid");
    this.fetchDocumentInfoUUID(uuid)
      .then(() => {
        this.dataAvailabilityStatus = true;
      })
      .catch(() => {
        this.dataAvailabilityStatus = false;
      });
  },
};
</script>
<style>
.header-title {
  font-family: arabic-title;
  font-size: 25px;
}

.overflowed-div {
  overflow-y: auto;
  max-height: 100vh !important;
}

.arabic-text {
  font-family: arabic-header !important;
  font-size: 20px;
}

.overflowed {
  max-height: 200px !important;
  height: 200px !important;
  overflow-y: auto;
}
</style>
