import axios from "@/axios";
import store from "../index";

const state = {
  agendas: {},
};

const getters = {
  agendaPrefix: () =>
    store.getters["authenticated"].role == "publisher"
      ? "publisher"
      : store.getters["authenticated"].role == "admin"
      ? "admin"
      : "user",
  getAllAgendas: (state) => state.agendas.data,
  getAgendaCurrentPage: (state) => state.agendas.current_page,
  getAgendaLastPage: (state) => state.agendas.last_page,
  getAgendaRecord: (state) => (id) => {
    var agendaRecord = state.agendas.data.find(
      (agendaRecord) => agendaRecord.id === id
    );
    return agendaRecord;
  },
};
const actions = {
  async fetchAgendas({ commit }, info) {
    const response = await axios.get(
      this.getters["agendaPrefix"] +
        "/documents?per_page=10&page=" +
        info.page +
        "&type=agenda&search=" +
        info.keyword +
        "&from=" +
        info.from +
        "&to=" +
        info.to +
        "&is_archived=" +
        info.is_archived
    );
    commit("SET_AGENDA", response.data);
  },
  async addNewAgenda({ commit }, Document) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post("/publisher/documents", Document);
    commit("SET_AGENDA_DATA", response.data);
  },

  async editAgendaFunction({ commit }, info) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post(
      "/publisher/documents/" + info.id,
      info.document
    );
    commit("SET_EDIT_AGENDA_DATA", response.data.data);
  },

  async switchArchiveFunction({ commit }, info) {
    const response = await axios.post(
      "/publisher/documents/" + info.get("id"),
      info
    );
    commit("SET_AGENDA_ARCHIVE", response.data.data.id);
  },

  setAgendaCurrentPage({ commit }, currentPage) {
    commit("SET_AGENDA_CURRENT_PAGE", currentPage);
  },
  setAgendaLastPage({ commit }, lastPage) {
    commit("SET_AGENDA_LAST_PAGE", lastPage);
  },
};

const mutations = {
  SET_AGENDA: (state, agenda) => (state.agendas = agenda),
  SET_AGENDA_DATA: (state, agenda) => (state.agendas = agenda),
  SET_AGENDA_CURRENT_PAGE: (state, data) => (state.agendas.current_page = data),
  SET_AGENDA_LAST_PAGE: (state, data) => (state.agendas.last_page = data),

  SET_EDIT_AGENDA_DATA: (state, payload) => {
    const i = state.agendas.data.map((item) => item.id).indexOf(payload.id);
    state.agendas.data[i].details = payload.details;
    state.agendas.data[i].subject = payload.subject;
    state.agendas.data[i].file_path = payload.file_path;
    state.agendas.data[i].is_certified = payload.is_certified;

    state.agendas.data = state.agendas.data.map((x) => {
      if (x.id === payload.id) {
        return Object.assign({}, x, payload.data);
      }
      return x;
    });
  },

  SET_AGENDA_ARCHIVE: (state, documentId) => {
    const i = state.agendas.data.map((item) => item.id).indexOf(documentId);
    state.agendas.data.splice(i, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
