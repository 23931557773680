import axios from "@/axios";
const state = {
  token: "",
  userInfo: {},
};
const getters = {
  authenticated: (state) => state.token && state.userInfo,
  is2faEnabled: (state) => state.userInfo.is_2fa_enabled,
  getUser: (state) => state.userInfo,
  isLoggedIn: (state) => (state.token ? true : false),
};
const actions = {
  async loginFun({ dispatch }, cred) {
    var obj = JSON.parse(cred);
    var otp = "";
    if (obj.otp != "") {
      otp = "?otp=" + obj.otp;
    }
    const response = await axios.post("/auth/login" + otp, {
      username: obj.username,
      password: obj.password,
    });
    if (response.data.access_token) {
      return dispatch("attempt", response.data.access_token);
    } else {
      if (response.data.message == "please enter 2fa secret key")
        return "enter2fa";
      if (response.data.error == "Unauthenticated") return "incorrect";
    }
  },
  async attempt({ commit, state }, token) {
    if (token) commit("setToken", token);

    if (!state.token) return;
    try {
      const response = await axios.post("/auth/me");
      localStorage.setItem("userInfo", JSON.stringify(response.data));
      commit("setUser", response.data);
      return response.data;
    } catch {
      console.log("err");
      commit("setToken", null);
      commit("setUser", null);
    }
  },
  async signout({ commit }) {
    await axios.post("/auth/logout").then(() => {
      commit("setToken", null);
      commit("setUser", null);
    });
  },

  async generateGoogleQr() {
    const response = await axios.post("/auth/2fa/generateGoogleSecretKey");
    return response;
  },

  async enable2fa({ commit }, otp) {
    const response = await axios.post("/auth/2fa/enableGoogle2fa?otp=" + otp);
    if (response.data.success == true) commit("TOGGLE_2FA", true);
  },
  async disable2fa({ commit }, otp) {
    const response = await axios.post("/auth/2fa/disableGoogle2fa?otp=" + otp);
    if (response.data.success == true) commit("TOGGLE_2FA", false);
  },
};
const mutations = {
  setToken: (state, user_token) => (state.token = user_token),
  setUser: (state, userInfo) => (state.userInfo = userInfo),
  TOGGLE_2FA: (state, status) => (state.userInfo.is_2fa_enabled = status),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
