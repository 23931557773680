import axios from "axios";
import store from "../store/index";
import router from "../router";
axios.create();

var domain = "https://council-cmc.iq";
//var domain = "http://127.0.0.1:8000";
//axios.defaults.baseURL = "http://127.0.0.1:8000/api";
//axios.defaults.baseURL = "https://development.cmc.iq/councilapi/public/api";
axios.defaults.baseURL = "https://api.council-cmc.iq/api";
axios.defaults.publicURL = domain + "/public-view";

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  (error) => {
    if (error.response.status == 401 || error.response.status == 403) {
      localStorage.setItem("user_token", null);
      store.dispatch("attempt", null);
      router.push({ name: "Login" });
    }
    return Promise.reject(error);
  }
);
axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
export default axios;
