import axios from "@/axios";
const state = {
  users: [],
};

const getters = {
  allUsersData: (state) => state.users.data,

  getUsers: (state) => (id) => {
    var user = state.users.data.find((user) => user.id === id);
    return user;
  },
};

const actions = {
  async fetchUsers({ commit }, page) {
    const response = await axios.get("/user/fetchUsers" + page);
    commit("SET_USERS", response.data);
  },

  async changePassword(_, info) {
    const response = await axios.post("/auth/resetPassword", info);

    if (response.data.message == "updated") {
      return "updated";
    } else return "failed";
  },
};
const mutations = {
  SET_USERS: (state, quantums) => (state.quantums = quantums),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
