import axios from "@/axios";
import store from "../index";

const state = {
  informations: {},
};

const getters = {
  informationPrefix: () =>
    store.getters["authenticated"].role == "publisher"
      ? "publisher"
      : store.getters["authenticated"].role == "admin"
      ? "admin"
      : "user",
  getAllinformations: (state) => state.informations.data,
  getInformationCurrentPage: (state) => state.informations.current_page,
  getInformationLastPage: (state) => state.informations.last_page,
  getInformationRecord: (state) => (id) => {
    var informationRecord = state.informations.data.find(
      (informationRecord) => informationRecord.id === id
    );
    return informationRecord;
  },
};
const actions = {
  async fetchinformations({ commit }, info) {
    const response = await axios.get(
      this.getters["informationPrefix"] +
        "/documents?per_page=10&page=" +
        info.page +
        "&type=informations&search=" +
        info.keyword +
        "&from=" +
        info.from +
        "&to=" +
        info.to +
        "&is_archived=" +
        info.is_archived
    );
    commit("SET_INFORMATIONS", response.data);
  },

  async addNewInformation({ commit }, Document) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post("/publisher/documents", Document);
    commit("SET_INFORMATIONS_DATA", response.data);
  },

  async editInformationFunction({ commit }, info) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post(
      "/publisher/documents/" + info.id,
      info.document
    );
    commit("SET_EDIT_INFO_DATA", response.data.data);
  },

  setInformationCurrentPage({ commit }, currentPage) {
    commit("SET_INFORMATIONS_CURRENT_PAGE", currentPage);
  },
  setInformationLastPage({ commit }, lastPage) {
    commit("SET_INFORMATIONS_LAST_PAGE", lastPage);
  },
};

const mutations = {
  SET_INFORMATIONS: (state, information) => (state.informations = information),
  SET_INFORMATIONS_DATA: (state, information) =>
    (state.informations = information),
  SET_INFORMATIONS_CURRENT_PAGE: (state, data) =>
    (state.informations.current_page = data),
  SET_INFORMATIONS_LAST_PAGE: (state, data) =>
    (state.informations.last_page = data),

  SET_EDIT_INFO_DATA: (state, payload) => {
    const i = state.informations.data
      .map((item) => item.id)
      .indexOf(payload.id);
    state.informations.data[i].details = payload.details;
    state.informations.data[i].subject = payload.subject;
    state.informations.data[i].file_path = payload.file_path;
    state.informations.data[i].is_certified = payload.is_certified;

    state.informations.data = state.informations.data.map((x) => {
      if (x.id === payload.id) {
        return Object.assign({}, x, payload.data);
      }
      return x;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
