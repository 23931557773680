<template>
  <div class="login">
    <v-container>
      <div>
        <v-row justify="center">
          <v-col cols="12" md="5" lg="5" xl="4" class="contentframe pb-8">
            <v-row class="px-8"
              ><v-col cols="4" class="text-center">
                <img
                  style="vertical-align:middle;"
                  width="90%"
                  height="auto"
                  src="../assets/imgs/BlueCMC.svg"
                  alt/></v-col
              ><v-col cols="8" class="pt-10 ">
                <h4 class="text-right blue-text ">
                  جمهورية العراق
                </h4>
                <h3 class="text-right blue-text">
                  هيئة الاعلام والاتصالات
                </h3>
              </v-col></v-row
            >

            <div class="px-5 mx-5 pt-5 text-right">
              <h4 class="mb-5 text-right">تسجيل الدخول</h4>
              <v-alert class="pt-3" v-if="feedback" dense type="warning">
                {{ feedback }}
              </v-alert>

              <v-form
                ref="loginForm"
                v-model="valid"
                lazy-validation
                v-if="otpStatus == false"
              >
                إسم المستخدم
                <v-text-field
                  auto
                  label="إسم المستخدم"
                  required
                  placeholder="إسم المستخدم"
                  id="user_name"
                  class="mt-2"
                  v-model="cred.username"
                  dense
                  outlined
                  :rules="requiredRule"
                ></v-text-field
                >كلمة المرور
                <v-text-field
                  label="كلمة المرور"
                  required
                  placeholder="كلمة المرور"
                  id="password"
                  :type="show1 ? 'text' : 'password'"
                  class="mt-2"
                  dense
                  outlined
                  v-model="cred.password"
                  :rules="requiredRule"
                  @keyup.enter="login"
                ></v-text-field>

                <v-btn
                  class="login-system-btn text-capitalize"
                  outlined
                  text
                  @click="login()"
                  ><v-icon left>mdi-login</v-icon> تسجيل الدخول</v-btn
                >
              </v-form>

              <v-form
                ref="otpForm"
                class="mt-2"
                v-if="otpStatus == true"
                dir="ltr"
              >
                <h5 class="text-right">
                  الرجاء كتابة رمز التحقق الخاص بالحساب لغرض تسجيل الدخول الى
                  النظام
                </h5>

                <h4 class="mt-5 text-center">
                  رمز التحقق
                </h4>
                <v-row>
                  <v-col cols="2" lg="4" sm="2" md="4"> </v-col>
                  <v-col cols="8" lg="4" sm="8" md="4" class="text-center mt-5">
                    <AppTimer :elapsed="timeElapsed" :limit="timeLimit" />
                  </v-col>
                  <v-col cols="2" lg="2" sm="2" md="4"> </v-col>
                </v-row>

                <v-col cols="12" class="mt-5">
                  <v-otp-input
                    v-model="cred.otp"
                    :disabled="loading"
                    @finish="approvedLogin()"
                    type="number"
                    class="small-text eng-text relative text-left "
                  ></v-otp-input>
                </v-col>
              </v-form>

              <v-card class=" mt-4 pa-4 text-right sys-card" elevation="0">
                <v-row dir="ltr">
                  <v-col cols="12" class="text-left pe-1 pt-2 ps-2 pe-3 ">
                    <span class="d-block eng-text blue-text comment-text">
                      Department of programming | IT Regulation directorate
                    </span>
                    <span class="d-block eng-text blue-text comment-text">
                      &copy; The Communication and Media Commission of Iraq
                      (CMC)
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import AppTimer from "../components/AppTimer.vue";

import { mapActions } from "vuex";
export default {
  name: "Loginpage",
  data: function() {
    return {
      timeElapsed: 0,
      timeLimit: 60,
      show1: false,
      cred: {
        username: "",
        password: "",
        otp: "",
      },
      countDown: 30,
      otpStatus: false,
      feedback: "",
      timeout: null,
      loading: false,
      valid: true,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
    };
  },
  components: {
    AppTimer,
  },
  mounted() {
    var token = localStorage.getItem("user_token");
    if (token)
      this.attempt(token).then((res) => {
        if (res.id) this.$router.push("/user/dashboard");
      });
  },
  methods: {
    ...mapActions(["loginFun", "attempt"]),

    startTimer() {
      this.timerInterval = setInterval(() => {
        // Stop counting when there is no more time left
        if (++this.timeElapsed === this.timeLimit) {
          clearInterval(this.timerInterval);
          this.timeElapsed = 0;
          this.timeLimit = 60;
          this.otpStatus = false;
          this.cred.username = "";
          this.cred.password = "";
          this.cred.otp = "";
        }
      }, 1000);
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    onHidden() {
      this.$refs.button.focus();
    },

    approvedLogin() {
      this.feedback = "";
      if (!this.$refs.otpForm.validate()) return;
      this.loginFun(JSON.stringify(this.cred))
        .then(() => {
          this.valid = false;
          this.cred.username = "";
          this.cred.password = "";
          this.cred.otp = "";
          this.$router.push("/user/dashboard");
        })
        .catch(() => {
          this.feedback = "رمز المستخدم غير صالح";

          this.cred.otp = "";
          setTimeout(() => {
            this.feedback = "";
          }, 5000);
        });
    },

    login() {
      if (!this.$refs.loginForm.validate()) return;
      this.loginFun(JSON.stringify(this.cred))
        .then((response) => {
          console.log(response);
          if (response == "enter2fa") {
            this.startTimer();
            this.countDownTimer();
            this.otpStatus = true;
          } else if (response.is_2fa_enabled == false) {
            this.otpStatus = false;
            this.valid = false;
            this.$router.push("/user/dashboard");
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 400:
              this.feedback = "تم حضر حسابك. الرجاء الاتصال بمدير النظام";
              break;
            default:
              this.feedback = "إسم المستخدم أو كلمة المرور غير صالحة";
              break;
          }
          setTimeout(() => {
            this.feedback = "";
          }, 5000);
        });
    },
  },
};
</script>

<style scoped lang="scss">
$accentColor: #2196f3;
$textColor: #fff;
$fcolor: #f1f1f1;
$bcolor: #f1f1f1;
$bgcolor: white;
$bgimage_path: "../assets/imgs/back.jpg";
$fontname: "JF Flat";

.login {
  min-height: 100vh;
  background: linear-gradient($fcolor, $bcolor);
  text-align: center;
  direction: rtl;
  font-family: $fontname;
  // background: url($bgimage_path);
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
}

.contentframe {
  background: $bgcolor;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 15px;
  margin-top: 5vh;
  padding-top: 4vh;
}

.centered-input {
  text-align: center !important;
}

h2 {
  text-align: center;
}

#blureField {
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: #fff;
}

.login-system-btn {
  background-color: #0f335f !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #f1f1f1 !important;
}

.login-system-btn-outline {
  background-color: transparent !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #0f335f !important;
  border: 1px solid #0f335f !important;
}

.right-bordered {
  border-right: 1px solid #0f335f !important;
}

@media only screen and (max-width: 600px) {
  .contentframe {
    background: $bgcolor;
    border: none;
    box-shadow: none;
    border-radius: 10px;
    margin-top: 0vh;
  }
  .login {
    background: #ffffff;
  }
}
</style>
