<template>
  <div id="composeMessageModal">
    <v-dialog
      persistent
      fullscreen
      transition="dialog-bottom-transition"
      v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="small-text ma-2 outlined"
          elevation="0"
        >
          <v-icon right dark class="mx-2">
            mdi-email-plus
          </v-icon>
          بريد جديد
        </v-btn>
      </template>
      <v-card v-click-outside="handleClickOutside" dir="rtl">
        <v-card-title>
          <v-col>
            <h5>
              <v-icon>
                mdi-email-plus
              </v-icon>
              بريد جديد
            </h5>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="addEmailForm" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-subheader class="titleaddress">وجهة البريد</v-subheader>
                  <v-autocomplete
                    v-model="reciever_id"
                    :items="allEmailReceivers"
                    item-value="id"
                    small-chips
                    multiple
                    outlined
                    required
                    dense
                    :rules="requiredRule2"
                    :item-text="textFormat"
                    class="small-text"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="4" class="text-right" dir="rtl">
                  <v-text-field
                    outlined
                    label="الموضوع"
                    hide-details="auto"
                    v-model="subject"
                    dense
                    class="text-right small-text"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="text-right" dir="rtl">
                  <v-text-field
                    outlined
                    label="العدد"
                    hide-details="auto"
                    v-model="number"
                    class="text-right small-text"
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="text-right" dir="rtl">
                  <v-menu
                    v-model="datePickerMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="email_date"
                        label="التاريخ"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        dense
                        outlined
                        :rules="requiredRule"
                        class="small-text"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="email_date"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    outlined
                    label="نص الرسالة"
                    value=""
                    v-model="message_detail"
                    :rules="requiredRule"
                    rows="2"
                    dense
                    class="small-text"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="9" class="text-right" dir="rtl">
                  <v-text-field
                    label="ملاحظات"
                    hide-details="auto"
                    v-model="notes"
                    class="text-right small-text"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col class="mb-4" cols="12" md="3">
                  <v-file-input
                    outlined
                    class="titleaddress small-text"
                    accept="application/pdf"
                    label="إرفاق الملفات"
                    multiple
                    show-size
                    id="braodcast_attachment"
                    v-model="Attachments"
                    :rules="requiredRule"
                    dense
                  ></v-file-input>
                </v-col>

                <v-alert dense text type="success" v-if="ComplaintSuccessAlert">
                  عملية الارسال <strong>تمت بنجاح</strong>
                </v-alert>
                <v-alert dense outlined type="error" v-if="ComplaintErrorAlert">
                  فشل في عملية الارسال الرجاء اعادة المحاولة
                </v-alert>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" lg="3" sm="12">
              <v-card-actions>
                <v-btn
                  :loading="loadingSpinner"
                  :disabled="loadingSpinner"
                  class="send-btn ps-2 pe-4 small-text"
                  @click="addMessage"
                  elevation="0"
                  ><v-icon small class="pe-2 ps-2">mdi-email</v-icon> ارسال
                  البريد
                </v-btn>
                <v-btn
                  color="darken-1"
                  class="outlined small-text"
                  text
                  @click="closeDialog"
                  >إلغاء</v-btn
                >
              </v-card-actions></v-col
            >
            <v-col cols="12" lg="9" sm="12" class="text-left">
              <img
                style="vertical-align:middle;"
                class="ps-1 pe-1 mt-1"
                width="auto"
                height="70px"
                src="../../../assets/imgs/BlueCMC.svg"
                alt
              />
              <img
                style="vertical-align:middle;"
                class="ps-1 pe-2 mt-1"
                width="auto"
                height="60px"
                src="../../../assets/imgs/inlayer-design.svg"
                alt
              />
            </v-col> </v-row
        ></v-container>
      </v-card>
    </v-dialog>
    <!-- 
    <v-snackbar
      :timeout="-1"
      bottom
      color="deep-purple accent-4"
      elevation="24"
      v-model="snackbar"
    >
      {{ alertText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "composeMessageModal",
  components: {},
  computed: {
    ...mapGetters(["allEmailReceivers"]),
  },
  created() {
    //get directories
    this.fetchEmailReceivers();
    //this.getReceiverDetails();
  },
  data() {
    return {
      loadingSpinner: false,
      datePickerMenu: false,
      receiver_list: [],
      reciever_id: [],
      message_detail: "",
      subject: "",
      number: "",
      notes: "",
      email_date: "",
      ComplaintSuccessAlert: false,
      ComplaintErrorAlert: false,
      alertText: null,
      snackbar: false,
      loader: null,
      loading3: false,
      valid: true,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      requiredRule2: [(v) => v.length > 0 || "هذا الحقل مطلوب"],
      Attachments: [],
      NewEmail: new FormData(),
      newFiles: new FormData(),
      dialog: false,
    };
  },

  methods: {
    ...mapActions(["fetchEmailReceivers", "addNewMail"]),
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.dialog = false;
    },

    textFormat(item) {
      if (item.name) {
        if (item.userdirectory) return item.name;
        else return item.name;
      }
    },
    addMessage() {
      this.NewEmail = new FormData();
      this.newFiles = new FormData();
      if (!this.$refs.addEmailForm.validate()) return;
      this.loadingSpinner = true;
      this.NewEmail.append("body", this.message_detail);
      this.NewEmail.append("subject", this.subject);
      this.NewEmail.append("number", this.number);
      this.NewEmail.append("notes", this.notes);
      this.NewEmail.append("date", this.email_date);
      this.loader = "loading3";

      for (let i = 0; i < this.reciever_id.length; i++) {
        this.NewEmail.append("email_receivers[" + i + "]", this.reciever_id[i]);
      }

      for (let i = 0; i < this.Attachments.length; i++) {
        this.NewEmail.append("email_files[" + i + "]", this.Attachments[i]);
      }

      this.addNewMail(this.NewEmail)
        .then(() => {
          this.loadingSpinner = false;
          this.ComplaintSuccessAlert = true;
          setTimeout(() => {
            this.dialog = false;
            this.alertText = null;
            this.snackbar = false;
            this.loader = null;
            this.loading3 = false;
            this.valid = false;
            this.receiver_list = [];
            this.reciever_id = [];
            this.message_detail = "";
            this.notes = "";
            this.fileIds = [];
            this.NewEmail = new FormData();
            this.newFiles = new FormData();
            this.ComplaintSuccessAlert = false;
            this.ComplaintErrorAlert = false;
            this.$refs.addEmailForm.reset();
          }, 3000);
        })
        .catch(() => {
          this.loadingSpinner = false;
          this.ComplaintErrorAlert = true;
          this.ComplaintSuccessAlert = false;
          setTimeout(() => {
            this.ComplaintErrorAlert = false;
          }, 3000);
        });
    },
  },
};
</script>

<style scoped>
* {
  font-weight: bold !important;
}

.send-btn {
  background-color: #0f335f !important;
  color: #f1f1f1 !important;
}

.textfields {
  max-height: 0px;
  vertical-align: middle;
}

.titleaddress {
  font-size: small;
  color: black;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  height: 25px;
}

h2 {
  text-align: center;
}

.outlined {
  background: transparent !important;
  border: 2px solid #0f335f;
}
</style>
