<template>
  <div id="composeMessageModal">
    <v-dialog
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="DecisionCertificateModalDialog"
    >
      <v-card v-click-outside="handleClickOutside" dir="rtl">
        <v-card-title>
          <v-col class="text-center">
            <h5>
              <v-icon small>
                mdi-pencil-box-outline
              </v-icon>
              تحويل حالة القرار
            </h5>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="CertificateForm" v-model="valid" lazy-validation>
              <v-row class="d-flex justify-center align-center">
                <v-col cols="12" lg="3" sm="12">
                  <v-card-actions>
                    <v-btn
                      :loading="loadingSpinner"
                      :disabled="loadingSpinner"
                      class="send-btn ps-2 pe-4 small-text d-block"
                      @click="CertificateOperation('yes')"
                      v-if="details.is_certified == null"
                      elevation="0"
                      ><v-icon small class="pe-1 ps-1 small-text"
                        >mdi-file-check-outline</v-icon
                      >
                      تحويل القرار الى نافذ
                    </v-btn>
                    <v-btn
                      :loading="loadingSpinner"
                      :disabled="loadingSpinner"
                      class="outlined ps-2 pe-4 small-text d-block"
                      @click="CertificateOperation('no')"
                      v-if="details.is_certified != null"
                      elevation="0"
                      ><v-icon small class="pe-1 ps-1 small-text"
                        >mdi-file-plus-outline</v-icon
                      >
                      تحويل القرار الى مشروع قرار جديد
                    </v-btn>
                    <v-btn
                      color="darken-1"
                      class="outlined small-text d-block"
                      text
                      @click="closeDialog"
                      >إلغاء</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-row>

              <v-row dir="ltr" class="d-flex justify-center align-center">
                <v-col cols="12" lg="8" class="text-center">
                  <v-alert
                    dense
                    text
                    type="success"
                    v-if="CertifiedSucessAlert"
                  >
                    عملية تحويل القرار <strong>تمت بنجاح</strong>
                  </v-alert>
                  <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="CertifiedErrorAlert"
                  >
                    فشل في عملية تحويل القرار الرجاء اعادة المحاولة
                  </v-alert>
                </v-col>
              </v-row>

              <v-row dir="ltr" class="d-flex justify-center align-center">
                <v-col cols="12" lg="8" md="8" class="pt-0 text-left">
                  <v-card class="pa-4 text-left" elevation="0">
                    <v-row dir="ltr">
                      <v-col
                        cols="4"
                        sm="4"
                        class="pa-0 pt-2 ps-0 pe-0 text-center"
                        v-show="$vuetify.breakpoint.mdAndUp"
                      >
                        <img
                          style="vertical-align:middle;"
                          class="ps-1 pe-1 mt-1"
                          width="auto"
                          height="70px"
                          src="../../../assets/imgs/BlueCMC.svg"
                          alt
                        />
                        <img
                          style="vertical-align:middle;"
                          class="ps-1 pe-2 mt-1"
                          width="auto"
                          height="60px"
                          src="../../../assets/imgs/inlayer-design.svg"
                          alt
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        lg="8"
                        class=" text-left pe-0 pt-2 pt-3"
                      >
                        <span class="d-block eng-text blue-text"> </span>
                        <span class="d-block eng-text blue-text">
                          Electronic Mail System V.2.0
                        </span>
                        <span class="d-block eng-text blue-text comment-text">
                          Department of programming | IT Regulation directorate
                        </span>
                        <span class="d-block eng-text blue-text comment-text">
                          &copy; The Communication and Media Commission of Iraq
                          (CMC)
                        </span>
                        <span class="d-block eng-text blue-text comment-text">
                          <v-spacer></v-spacer>
                          Version:2.0 / Production
                          {{ new Date().getFullYear() }} / SYS-ID:85
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["DecisionCertificateModalDialog", "details"],
  name: "editDecisionModal",
  computed: {
    ...mapGetters(["authenticated"]),
  },
  created() {},
  data() {
    return {
      loadingSpinner: false,
      datePickerMenu: false,
      CertifiedSucessAlert: false,
      CertifiedErrorAlert: false,
      alertText: null,
      snackbar: false,
      loader: null,
      loading3: false,
      valid: true,
      Attachments: null,
      NewCertificate: new FormData(),
      dialog: false,
      info: {},
      comments: null,
    };
  },

  methods: {
    ...mapActions(["editDecision"]),
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit("DecisionCertificateModalDialogClosed", false);
    },
    textFormat(item) {
      if (item.name) {
        if (item.userdirectory) return item.name;
        else return item.name;
      }
    },

    CertificateOperation(certificate) {
      this.NewDecision = new FormData();
      if (!this.$refs.CertificateForm.validate()) return;
      this.loadingSpinner = true;
      this.NewDecision.append("isCertified", certificate);
      this.NewDecision.append("_method", "put");
      this.loader = "loading3";
      this.info.document = this.NewDecision;
      this.info.id = this.details.id;
      this.editDecision(this.info)
        .then(() => {
          this.loadingSpinner = false;
          this.CertifiedSucessAlert = true;
          setTimeout(() => {
            this.$emit("DecisionCertificateModalDialogClosed", false);
            this.$emit("newDecisionDetailsDialogClosed", false);
            this.alertText = null;
            this.snackbar = false;
            this.loader = null;
            this.loading3 = false;
            this.valid = false;
            this.NewDecision = new FormData();
            this.CertifiedSucessAlert = false;
            this.CertifiedErrorAlert = false;
            //this.$refs.CertificateForm.reset();
          }, 3000);
        })
        .catch(() => {
          this.loadingSpinner = false;
          this.CertifiedErrorAlert = true;
          this.CertifiedSucessAlert = false;
          setTimeout(() => {
            this.ComplaintErrorAlert = false;
          }, 3000);
        });
    },
  },
};
</script>

<style scoped>
* {
  font-weight: bold !important;
}

.send-btn {
  background-color: #0f335f !important;
  color: #f1f1f1 !important;
}

.textfields {
  max-height: 0px;
  vertical-align: middle;
}

.titleaddress {
  font-size: small;
  color: black;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  height: 25px;
}

h2 {
  text-align: center;
}

.outlined {
  background: transparent !important;
  border: 2px solid #0f335f;
}
</style>
