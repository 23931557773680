<template>
  <div class="userinfo">
    <v-container>
      <form>
        <v-row class="px-3 text-right">
          <v-col cols="1" class="pa-7">
            <v-icon x-large class="eng-text" v-if="$vuetify.breakpoint.mdAndUp"
              >mdi-account-circle-outline</v-icon
            >
          </v-col>
          <v-col cols="11">
            <h5 class="mb-2">الاسم: {{ userInfo.name }}</h5>
            <h5 class="mb-2">اسم المستخدم: {{ userInfo.username }}</h5>
            <h5>تاريخ الانشاء: {{ userInfo.created_at.substring(0, 10) }}</h5>
            <ChangePassword class="mb-3" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1" class="pa-7">
            <v-icon x-large class="eng-text" v-if="$vuetify.breakpoint.mdAndUp"
              >mdi-shield-account-outline</v-icon
            >
          </v-col>
          <v-col cols="11 text-right">
            <v-row>
              <v-col cols="12" lg="6">
                <h5 class="pt-1 green--text" v-if="is2faEnabled == true">
                  المصادقة الثنائية فعالة
                </h5>
                <h5 class="pt-1 red--text" v-if="is2faEnabled == false">
                  المصادقة الثنائية غيرفعالة
                </h5>
                <h5 class="eng-text">
                  Please enable the two-factor authentication
                </h5>
                <v-switch
                  v-model="is2faEnabledStatus"
                  inset
                  @change="getQrUrl"
                  label="`تفعيل المصادقة الثانية"
                  class="small-text"
                ></v-switch>
                <div>
                  <v-col
                    cols="6"
                    v-if="is2faEnabledStatus == true && is2faEnabled == false"
                  >
                    <div v-html="QRSRC"></div>

                    <h5>
                      يرجى مسح الرمز اعلاه عن طريق تطبيق Google Authenticator
                    </h5>
                  </v-col>
                  <div dir="ltr" style="max-width: 300px">
                    <v-otp-input
                      v-model="otp"
                      :disabled="loading"
                      @finish="toggle2faStatusFn"
                      class="small-text text-left"
                      type="number"
                    ></v-otp-input>
                  </div>
                  <v-col cols="12" md="8">
                    <v-alert dense text type="success" v-if="otpSuccessAlert">
                      {{ feedback }}<strong>تمت بنجاح</strong>
                    </v-alert>
                    <v-alert dense outlined type="error" v-if="otpErrorAlert">
                      {{ feedback }}
                    </v-alert>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="4" v-show="$vuetify.breakpoint.mdAndUp">
                <v-img
                  :src="require('../assets/imgs/twofactor.svg')"
                  height="auto"
                  width="100%"
                  contain
                  class="text-center ps-8 pe-8"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </form>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ChangePassword from "../components/Models/ChangePassword";
export default {
  name: "userInfo",
  components: { ChangePassword },

  data() {
    return {
      userInfo: {},
      QRSRC: "",
      loading: false,
      otp: "",
      otpSuccessAlert: false,
      otpErrorAlert: false,
      is2faEnabledStatus: this.is2faEnabled,
      feedback: "",
    };
  },

  computed: {
    ...mapGetters(["is2faEnabled"]),
  },
  methods: {
    ...mapActions(["generateGoogleQr", "enable2fa", "disable2fa"]),

    getQrUrl() {
      if (this.is2faEnabledStatus == true) {
        this.generateGoogleQr().then((res) => {
          this.QRSRC = res.data.url;
        });
      }
    },

    toggle2faStatusFn() {
      //enable
      if (this.is2faEnabledStatus == true)
        this.enable2fa(this.otp)
          .then(() => {
            this.feedback = " عملية تفعيل المصادقة الثنائية ";
            this.otpSuccessAlert = true;
            this.otpErrorAlert = false;
          })
          .catch((err) => {
            this.feedback = "";
            if (err.response.data) {
              if (err.response.data.message == "wrong otp")
                this.feedback = "الرمز الذي قمت بادخاله غير صحيح!";
              else this.feedback = "فشل في العملية يرجى المحاولة لاحقا";
            } else this.feedback = "فشل في العملية يرجى المحاولة لاحقا";

            this.otpSuccessAlert = false;
            this.otpErrorAlert = true;
          });
      //disable
      if (this.is2faEnabledStatus == false) {
        console.log("hi");
        this.disable2fa(this.otp)
          .then(() => {
            this.feedback = " عملية الغاء المصادقة الثنائية";
            this.otpSuccessAlert = true;
            this.otpErrorAlert = false;
          })
          .catch((err) => {
            this.feedback = "";
            if (err.response.data) {
              if (err.response.data.message == "wrong otp")
                this.feedback = "الرمز الذي قمت بادخاله غير صحيح!";
              else this.feedback = "فشل في العملية يرجى المحاولة لاحقا";
            } else this.feedback = "فشل في العملية يرجى المحاولة لاحقا";

            this.otpSuccessAlert = false;
            this.otpErrorAlert = true;
          });
      }
    },
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.is2faEnabledStatus = this.is2faEnabled;
  },
};
</script>

<style scoped lang="scss">
$accentColor: #2196f3;
$textColor: #fff;
$fcolor: rgb(100, 20, 80);
$bcolor: black;
$bgcolor: white;
$bgimage_path: "../assets/imgs/leftbackground.png";
$fontname: "JF Flat";

.userinfo {
  border: none;
  border-radius: 20px;
  vertical-align: middle;
  text-align: center;
  direction: rtl;
  font-family: $fontname;
}

.texttitles {
  font-size: large;
}

h3 {
  font-weight: 100;
}
</style>
