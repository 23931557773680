<template>
  <div class="text-right">
    <v-dialog
      persistent
      width="500"
      transition="dialog-bottom-transition"
      :value="decisionSearchDialog"
    >
    <v-card v-click-outside="handleClickOutside" class="text-right ">

      <v-form ref="searchForm" v-model="valid" lazy-validation>
          <v-card-text>
            البحث المتقدم
          </v-card-text>

          <v-divider></v-divider>

          <v-container>
            <h6>من</h6>
            <v-text-field
              type="date"
              placeholder="من"
              outlined
              dense
              :rules="requiredRule"
              v-model="info.from"
              class="mt-2 eng-text"
            ></v-text-field>

            <h6>الى</h6>
            <v-text-field
              type="date"
              placeholder="الى"
              outlined
              dense
              :rules="requiredRule"
              v-model="info.to"
              class="mt-2 eng-text"
            ></v-text-field>
          </v-container>

          <v-card-actions>
            <v-btn class="send-btn small-text" text @click="SearchFunction">
              بحث متقدم
            </v-btn>
            <v-btn class="outlined small-text" text @click="closeDialog">
              الغاء
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
      </v-form>

        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["decisionSearchDialog"],
  data() {
    return {
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      valid: false,
      info: {from:null,to:null},
      dialog: false,
    };
  },
 
  methods: {
    ...mapActions(["fetchInboxMails"]),
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") {
        this.closeDialog();
      }
    },
    SearchFunction() {
      if (!this.$refs.searchForm.validate()) return;
      
      this.$emit("searchDateChanged", this.info);
      this.closeDialog();
    },

    closeDialog() {
      this.$emit("decisionSearchDialogClosed", false);
    },
  },
};
</script>

<style>
v-text-field {
  direction: rtl !important;
}

.eng-text {
  font-family: sans-serif !important;
  color: gray;
}

.send-btn {
  background-color: #0f335f !important;
  color: #f1f1f1 !important;
}

.outlined {
  background: transparent !important;
  border: 1px solid #0f335f;
  color: #0f335f !important;
}
</style>
