<template>
  <div>
    <v-dialog
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="CoverLetterModel"
      :clickToClose="false"
    >
      <div class="decision-details">
        <v-container fluid class="bg-gray pa-4 pb-6">
          <v-row>
            <v-btn
              color="darken-1"
              class="outlined mt-2  ms-1 me-1 "
              text
              @click="closeDialog"
            >
              <v-icon small>mdi-close-circle-outline</v-icon>
              اغلاق</v-btn
            >

            <v-btn
              class="outlined mt-2  small-text ms-1 me-1"
              text
              @click="generateReport"
              ><v-icon small>mdi-file-pdf-box</v-icon>
              <span>معاينة القرار</span></v-btn
            >
          </v-row>
        </v-container>

        <v-container fluid>
          <v-row>
            <v-col cols="2" class="bg-gray2 pa-0 scroll-y">
              <v-container dir="rtl" class="ps-4 pe-4">
                <v-form
                  ref="editDecisionForm"
                  v-model="valid"
                  lazy-validation
                  v-if="authenticated.role == 'publisher'"
                >
                  <v-row>
                    <v-col cols="12">
                      <h6 class=" pa-2">
                        الرجاء مليء المعلومات قبل الطباعة
                      </h6>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        label="رقم القرار"
                        hide-details="auto"
                        v-model="decisionNumber"
                        class="text-right small-text"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="العدد"
                        hide-details="auto"
                        v-model="decisionDocNumber"
                        class="text-right small-text"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-btn
                        class="outlined   small-text "
                        text
                        @click="registerInfo"
                        ><v-icon small>mdi-file-document</v-icon>
                        <span>تسجيل المعلومات </span></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>

                <v-col cols="12">
                  <v-col cols="12" class="text-center">
                    <qr-code
                      :text="createLink(details.uuid)"
                      class="text-center"
                      :size="150"
                      error-level="H"
                      color="#0f335f"
                      :value="createLink(details.uuid)"
                    ></qr-code>
                  </v-col>
                </v-col>
                <v-col cols="12">
                  <v-alert
                    dense
                    text
                    type="success"
                    v-if="ComplaintSuccessAlert"
                    class="small-text"
                  >
                    عملية اضافة رقم القرار والعدد
                    <strong>تمت بنجاح</strong>
                  </v-alert>
                  <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="ComplaintErrorAlert"
                    class="small-text"
                  >
                    فشل في عملية الاضافة الرجاء اعادة المحاولة
                  </v-alert>
                </v-col>
              </v-container>
            </v-col>
            <v-col cols="10 pa-0">
              <v-container fluid dir="rtl" class="bg-white ps-4 pe-4">
                <v-row class="ps-10 pe-10">
                  <v-col cols="4" lg="4" md="4" sm="4">
                    <h4>جمهورية العراق</h4>
                    <h4>هيئة الأعلام والأتصالات</h4>
                    <h4>مجلس المفوضين</h4>
                  </v-col>
                  <v-col
                    cols="4"
                    lg="4"
                    md="4"
                    sm="4"
                    text-center
                    class="text-center"
                  >
                    <img
                      width="50%"
                      height="auto"
                      src="../../../assets/imgs/cmclogo.png"
                      alt
                      style="vertical-align: middle;"
                    />
                  </v-col>
                  <v-col cols="4" lg="4" md="4" sm="4" dir="ltr">
                    <h4>Republic of Iraq</h4>
                    <h4>The Communication and Media Commission</h4>
                    <h4>The Board of Commissioners</h4>
                  </v-col>
                </v-row>

                <v-row class="ps-10 pe-10">
                  <v-col cols="12" class="text-right ">
                    <h4>الرقم/ {{ details.decisionNumber }}</h4>
                    <h4>العدد/ {{ details.documentNumber }}</h4>
                    <h4>التاريخ/ {{ details.doc_date }}</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-center">
                    <h4>م/ {{ details.subject }}</h4>
                  </v-col>
                </v-row>
                <v-row class="pt-4 ps-10 pe-10">
                  <v-col cols="12" class="text-justify">
                    <span>{{ details.details }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="3"></v-col>
                  <v-col cols="3"></v-col>
                  <v-col cols="3"></v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>

        <div>
          <VueHtml2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="myPDF"
            :pdf-quality="5"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
          >
            <section slot="pdf-content" class="pa-4" dir="rtl">
              <!-- PDF Content Here -->
              <v-container style="padding:5%;">
                <!-- <v-row>
                  <v-col cols="5" lg="5" md="5" sm="5">
                    <p
                      style="font-family:arabic-header;font-size:20px;padding-bottom:5px;"
                    >
                      جمهورية العراق
                    </p>
                    <p
                      style="font-family:arabic-header;font-size:20px;padding-bottom:5px;"
                    >
                      هيئة الأعلام والأتصالات
                    </p>
                    <p
                      style="font-family:arabic-header;font-size:25px;padding-bottom:5px;"
                    >
                      مجلس المفوضين
                    </p>
                  </v-col>
                  <v-col
                    cols="2"
                    lg="2"
                    md="2"
                    sm="2"
                    text-center
                    style="margin: auto;text-align: center;"
                  >
                    <img
                      width="80%"
                      height="auto"
                      src="../../../assets/imgs/cmclogo.png"
                      alt
                      style="vertical-align: middle;"
                    />
                  </v-col>
                  <v-col cols="5" lg="5" md="5" sm="5" dir="ltr">
                    <p style="font-family:eng-text;">Republic of Iraq</p>
                    <p style="font-family:eng-text;">
                      The Communication and Media Commission
                    </p>
                    <p style="font-family:eng-text;">
                      The Board of Commissioners
                    </p>
                  </v-col>
                </v-row> -->
                <br />
                <br />
                <br />
                <br />
                <br />
                <v-row>
                  <v-col cols="12" style="margin: auto;text-align: right;">
                    <h4 style="font-family:numbering;">
                      رقم القرار
                      <span style="font-family:numbering;padding-right:5px;">
                        : {{ details.decisionNumber }}</span
                      >
                    </h4>

                    <h4 style="font-family:numbering;">
                      العدد
                      <span style="font-family:numbering;padding-right:5px;">
                        : {{ details.documentNumber }}</span
                      >
                    </h4>
                    <h4 style="font-family:numbering;">
                      التاريخ
                      <span style="font-family:numbering;padding-right:5px;">
                        : {{ details.doc_date }}</span
                      >
                    </h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" style="margin: auto;text-align: center;">
                    <h4 style="font-family: arabic-header;font-size:25px;">
                      م
                      <span style="font-family: arabic-header;font-size:25px;">
                        / {{ details.subject }}</span
                      >
                    </h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    style="text-align: justify;font-family: arabic-header;font-size:20px;"
                  >
                    {{ details.details }}
                  </v-col>
                  <v-col cols="4"></v-col>
                  <v-col cols="3"></v-col>
                  <v-col cols="4" style="margin-top:100px; text-align:center;">
                    <p style="font-family: arabic-header;font-size:30px;">
                      رئيس مجلس المفوضين
                    </p>
                    <span style="font-family:numbering;">{{
                      details.doc_date
                    }}</span>
                  </v-col>
                  <v-col cols="12" style="text-align:center">
                    <qr-code
                      :text="createLink(details.uuid)"
                      class="text-center"
                      :size="100"
                      error-level="H"
                      color="#0f335f"
                      :value="createLink(details.uuid)"
                    ></qr-code>
                  </v-col>
                </v-row>
                <br />
                <br />
                <br />
                <br />
              </v-container>
            </section>
          </VueHtml2pdf>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
//import { mapActions } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import axios from "@/axios";

import { mapActions, mapGetters } from "vuex";

export default {
  props: ["CoverLetterModel", "details"],
  name: "VotingDecisionModal",
  computed: {
    ...mapGetters(["authenticated"]),
  },
  created() {},
  data() {
    return {
      valid: true,
      dialog: false,
      decisionNumber: "",
      decisionDocNumber: "",
      editDecisionFormData: new FormData(),
      ComplaintSuccessAlert: false,
      ComplaintErrorAlert: false,
      alertText: null,
      snackbar: false,
    };
  },
  components: {
    VueHtml2pdf,
  },
  methods: {
    ...mapActions(["editApprovedDecision"]),
    createLink(uuid) {
      return axios.defaults.publicURL + "?uuid=" + uuid;
    },
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit("CoverLetterModelClosed", false);
    },

    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    registerInfo() {
      this.editDecisionFormData = new FormData();
      if (!this.$refs.editDecisionForm.validate()) return;
      this.loadingSpinner = true;
      this.editDecisionFormData.append("id", this.details.id);
      this.editDecisionFormData.append("decisionNumber", this.decisionNumber);
      this.editDecisionFormData.append("isCertified", "yes");
      this.editDecisionFormData.append(
        "documentNumber",
        this.decisionDocNumber
      );
      this.editDecisionFormData.append("_method", "put");
      this.loader = "loading3";

      this.editApprovedDecision(this.editDecisionFormData)
        .then(() => {
          this.loadingSpinner = false;
          this.ComplaintSuccessAlert = true;

          setTimeout(() => {
            this.alertText = null;
            this.snackbar = false;
            this.loader = null;
            this.loading3 = false;
            this.valid = false;

            this.NewDecision = new FormData();
            this.ComplaintSuccessAlert = false;
            this.ComplaintErrorAlert = false;
          }, 8000);
        })
        .catch(() => {
          this.loadingSpinner = false;
          this.ComplaintErrorAlert = true;
          this.ComplaintSuccessAlert = false;
        });
    },
  },
};
</script>

<style scoped>
.bg-white {
  background: #ffff !important;
}

.bg-gray {
  background: #eceff1 !important;
}

.bg-gray2 {
  background: #fafafa !important;
}

.scroll-y {
  overflow-y: auto;
  max-height: 91vh !important;
  height: 91vh !important;
  padding-bottom: 3rem;
}

.multiline {
  white-space: pre-wrap;
}
</style>
