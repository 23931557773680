<template>
  <div>
    <v-row dir="rtl">
      <v-col
        cols="12"
        md="3"
        v-for="(card, index) in statisticsCards"
        :key="index"
      >
        <v-card
          elevation="0"
          class="pt-0 pb-0 hoverable-card"
          @click="$router.push(card.link)"
        >
          <v-col cols="12" md="12" lg="12" sm="12" class="pt-0 pb-0 ">
            <v-card-text class="pt-0 pb-0  text-center">
              <v-img
                :src="require('../../../assets/imgs/' + card.img)"
                height="auto"
                width="100%"
                contain
                class="text-center ps-8 pe-8"
              />
            </v-card-text>
            <v-divider></v-divider>
          </v-col>

          <v-card-subtitle class="pt-1 subtitle-custom pb-4 text-center">
            <a @click="$router.push(card.link)">
              {{ card.link_title }}
            </a></v-card-subtitle
          >
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "mainPage",
  components: {},

  data() {
    return {
      search: "",
      iconsColor: "#4fce82",
      statisticsCards: [
        {
          img: "Data-report-bro-1.svg",
          icon: "mdi-email-receive",
          link: "/user/dashboard/decisions/approved-decisions",
          link_title: "القرارات النافذة",
        },
        {
          img: "finance.svg",
          icon: "mdi-email-receive",
          link: "/user/dashboard/decisions/new-decisions",
          link_title: "مشاريع القرارات",
        },
        {
          img: "Report2.svg",
          icon: "mdi-email-send",
          link: "/user/dashboard/decisions/work-schedule",
          link_title: "جدول اعمال",
        },
        {
          img: "archiveDocument.svg",
          icon: "mdi-email-send",
          link: "/user/dashboard/decisions/archive-work-schedule",
          link_title: "ارشيف جدول اعمال",
        },
      ],
    };
  },
};
</script>

<style></style>
