<template>
  <div v-if="getCounters">
    <v-row dir="rtl">
      <v-col cols="12" md="3" v-for="(card,index) in statisticsCards" :key="index">
        <v-card elevation="0" class="pt-0 pb-0 hoverable-card text-center">
          <v-col
            cols="12"
            md="12"
            lg="12"
            sm="12"
            class="pt-0 pb-0 text-center"
          >
            <h1 class="eng-text pt-5 subtitle-custom pb-4 text-center">
              {{ getCounters[card.countName] }}
            </h1>
            <v-divider></v-divider>
          </v-col>

          <v-card-subtitle class="pt-1 subtitle-custom pb-4 text-center">
            {{ card.link_title }}
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ReportPage",
  components: {},

  computed: {
    ...mapGetters(["getCounters"]),
  },
  created() {
    this.fetchCounters();
  },
  methods: {
    ...mapActions(["fetchCounters"]),
  },

  data() {
    return {
      search: "",
      statisticsCards: [
        {
          img: "mailbox_final.svg",
          icon: "mdi-email-receive",
          title: "البريد المستلم",
          link_title: " صندوق البريد",
          countName: "receivedMail",
        },

        {
          img: "sent_mail.svg",
          icon: "mdi-email-send",
          title: "عدد البريد المرسل",
          link_title: "  البريد المرسل",
          countName: "sentMail",
        },

        {
          img: "decisions.svg",
          icon: "mdi-file-sign",
          title: "القرارات النافذة",
          link_title: " القرارات النافذة",
          countName: "documents_decision_certified",
        },

        {
          img: "decisions.svg",
          icon: "mdi-email-send",
          title: "مشاريع القرارات ",
          link_title: " مشاريع القرارات ",
          countName: "documents_decision_not_certified",
        },
        {
          img: "decisions.svg",
          icon: "mdi-email-send",
          title: "جدول الأعمال",
          link_title: "جدول الاعمال",
          countName: "documents_agenda",
        },

        {
          img: "personal_gray.svg",
          icon: "mdi-email-send",
          title: "ملفات للطلاع",
          link_title: " ملفات للأطلاع",
          countName: "documents_informations",
        },

        {
          img: "personal_gray.svg",
          icon: "mdi-email-send",
          title: " نشاطات النظام",
          countName: "history",
          link_title: "نشاطات النظام",
        },
      ],
    };
  },
};
</script>

<style>
.subtitle-custom {
  color: gray !important;
  font-weight: normal;
}
</style>
