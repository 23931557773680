//import axios from "@/axios";
const state = {
  directories: {}
};

const getters = {
  allDirectories: state => state.directories.data
};
const actions = {};
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
