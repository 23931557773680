<template>
  <div id="composeMessageModal">
    <v-dialog
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :value="NotesEditDialog"
    >
      <v-card v-click-outside="handleClickOutside" dir="rtl">
        <v-card-title>
          <v-col>
            <h5 class="text-center">
              <v-icon>
                mdi-file-plus-outline
              </v-icon>
              تعديل بيانات جدول الأعمال
            </h5>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="editInfoForm" v-model="valid" lazy-validation>
              <v-row class="justify-center align-center">
                <v-col cols="12" md="6" class="text-right" dir="rtl">
                  <v-menu
                    v-model="datePickerMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="details.doc_date"
                        label="التاريخ"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        outlined
                        dense
                        :rules="requiredRule"
                        class="small-text eng-text"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="details.doc_date"
                      @input="menu2 = false"
                      class="eng-text"
                    ></v-date-picker>
                  </v-menu>

                  <v-textarea
                    v-model="details.details"
                    outlined
                    label=" تفاصيل"
                    value=""
                    :rules="requiredRule"
                    rows="2"
                    class="small-text"
                  ></v-textarea>

                  <v-file-input
                    outlined
                    class="titleaddress small-text"
                    accept="application/pdf"
                    label="إرفاق الملفات"
                    show-size
                    id="braodcast_attachment"
                    v-model="Attachments"
                    dense
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row class="justify-center align-center mt-8">
                <v-col cols="12" md="6">
                  <v-alert
                    dense
                    text
                    type="success"
                    v-if="ComplaintSuccessAlert"
                  >
                    عملية تعديل ملفات الأطلاع <strong>تمت بنجاح</strong>
                  </v-alert>
                  <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="ComplaintErrorAlert"
                  >
                    فشل في عملية التعديل الرجاء اعادة المحاولة
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-container>
          <v-row class="justify-center align-center">
            <v-col cols="12" lg="3" sm="12">
              <v-card-actions>
                <v-btn
                  :loading="loadingSpinner"
                  :disabled="loadingSpinner"
                  class="send-btn ps-2 pe-4 small-text"
                  @click="addMessage"
                  elevation="0"
                  ><v-icon small class="pe-1 ps-1"
                    >mdi-file-plus-outline</v-icon
                  >
                  تسجيل جدول الاعمال
                </v-btn>
                <v-btn
                  color="darken-1"
                  class="outlined small-text"
                  text
                  @click="closeDialog"
                  >إلغاء</v-btn
                >
              </v-card-actions></v-col
            >
            <v-col cols="12" lg="12" sm="12" class="text-left mt-2">
              <v-card class="justify-center align-center" elevation="0">
                <v-col
                  cols="12"
                  sm="12"
                  class="pa-0 pt-2 ps-0 pe-0 text-center "
                  v-show="$vuetify.breakpoint.mdAndUp"
                >
                  <img
                    style="vertical-align:middle;"
                    class="ps-1 pe-1 mt-1"
                    width="auto"
                    height="70px"
                    src="../../../assets/imgs/BlueCMC.svg"
                    alt
                  />
                  <img
                    style="vertical-align:middle;"
                    class="ps-1 pe-2 mt-1"
                    width="auto"
                    height="60px"
                    src="../../../assets/imgs/inlayer-design.svg"
                    alt
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  lg="12"
                  class=" text-center pe-0 pt-2 pt-3"
                >
                  <span class="d-block eng-text blue-text"> </span>
                  <span class="d-block eng-text blue-text">
                    Electronic Mail System V.2.0
                  </span>
                  <span class="d-block eng-text blue-text comment-text">
                    Department of programming | IT Regulation directorate
                  </span>
                  <span class="d-block eng-text blue-text comment-text">
                    &copy; The Communication and Media Commission of Iraq (CMC)
                  </span>
                  <span class="d-block eng-text blue-text comment-text">
                    <v-spacer></v-spacer>
                    Version:2.0 / Production
                    {{ new Date().getFullYear() }} / SYS-ID:85
                  </span>
                </v-col>
              </v-card>
            </v-col>
          </v-row></v-container
        >
      </v-card>
    </v-dialog>
    <!-- 
    <v-snackbar
      :timeout="-1"
      bottom
      color="deep-purple accent-4"
      elevation="24"
      v-model="snackbar"
    >
      {{ alertText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["NotesEditDialog", "details"],

  name: "NotesEditModal",
  components: {},
  computed: {
    //...mapGetters(["allEmailReceivers"]),
  },
  created() {},
  data() {
    return {
      loadingSpinner: false,
      datePickerMenu: false,
      ComplaintSuccessAlert: false,
      ComplaintErrorAlert: false,
      alertText: null,
      snackbar: false,
      loader: null,
      loading3: false,
      valid: true,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      requiredRule2: [(v) => v.length > 0 || "هذا الحقل مطلوب"],
      Attachments: [],
      NewInformation: new FormData(),
      info: {},
    };
  },

  methods: {
    ...mapActions(["editInformationFunction"]),
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit("NotesEditDialogClosed", false);
    },

    textFormat(item) {
      if (item.name) {
        if (item.userdirectory) return item.name;
        else return item.name;
      }
    },
    addMessage() {
      this.NewInformation = new FormData();
      if (!this.$refs.editInfoForm.validate()) return;
      this.loadingSpinner = true;

      this.NewInformation.append("subject", this.details.subject);
      this.NewInformation.append("docDate", this.details.doc_date);
      this.NewInformation.append("details", this.details.details);
      if (this.Attachments) {
        for (let i = 0; i < this.Attachments.length; i++) {
          this.NewInformation.append("file[" + i + "]", this.Attachments[i]);
        }
      }
      this.NewInformation.append("isCertified", "no");
      this.NewInformation.append("_method", "put");
      this.loader = "loading3";

      this.info.document = this.NewInformation;
      this.info.id = this.details.id;

      this.editInformationFunction(this.info)
        .then(() => {
          this.loadingSpinner = false;
          this.ComplaintSuccessAlert = true;
          setTimeout(() => {
            this.alertText = null;
            this.snackbar = false;
            this.loader = null;
            this.loading3 = false;
            this.valid = false;
            this.NewInformation = new FormData();
            this.ComplaintSuccessAlert = false;
            this.ComplaintErrorAlert = false;
          }, 3000);
        })
        .catch(() => {
          this.loadingSpinner = false;
          this.ComplaintErrorAlert = true;
          this.ComplaintSuccessAlert = false;
          setTimeout(() => {
            this.ComplaintErrorAlert = false;
          }, 3000);
        });
    },
  },
};
</script>

<style scoped>
* {
  font-weight: bold !important;
}

.send-btn {
  background-color: #0f335f !important;
  color: #f1f1f1 !important;
}

.textfields {
  max-height: 0px;
  vertical-align: middle;
}

.titleaddress {
  font-size: small;
  color: black;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  height: 25px;
}

h2 {
  text-align: center;
}

.outlined {
  background: transparent !important;
  border: 2px solid #0f335f;
}
</style>
