<template>
  <div class="inboxes">
    <v-card class="pb-3 custom-data-table" elevation="0">
      <v-card-title>
        <v-row dense>
          <v-col cols="12" md="2" class="text-center">
            <NewDecisionModal />
          </v-col>

          <v-col cols="12" md="6" class="pt-3 text-center">
            <v-text-field
              dense
              justify-center
              v-model="Info.keyword"
              label="بحث"
              single-line
              hide-details
              outlined
              class="small-text"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="4" s class="text-right pt-1">
            <v-btn @click="showAllDecisionProject()" icon color="blue">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn
              elevation="0"
              color="#0f335f"
              class="small-text  ma-2 white--text"
              @click="searchMediaData()"
            >
              <v-icon left dark>
                mdi-magnify
              </v-icon>
              بحث
            </v-btn>

            <v-btn
              class="small-text ma-2 outlined"
              elevation="0"
              @click="showAdvancedSearchDialog()"
            >
              <v-icon right dark class="mx-1">
                mdi-magnify-plus-outline
              </v-icon>
              بحث متقدم
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        align-center
        :headers="headers"
        :items="getAllDecisionProjects"
        hide-default-footer
        fixed-header
        dir="rtl"
        class="small-text "
      >
        <template v-slot:item.id="{ item }">
          <span class="eng-text">
            {{ padLeadingZeros(item.id, 4) }}
          </span>
        </template>
        <template v-slot:item.doc_date="{ item }">
          <span class="eng-text">
            {{ item.doc_date }}
          </span>
        </template>

        <!--  <template v-slot:item.voters="{ item }">
          <span
            dir="ltr"
            class="d-block mt-1 text-center"
            v-for="(voter, key) in item.voters"
            :key="key"
          >
            <span class="username eng-text"> {{ voter.title }} </span>

            <span v-if="voter.state == 'yes'">
              <v-icon class="green-icon ms-2 me-2"
                >mdi-check-circle-outline</v-icon
              >
            </span>
            <span v-if="voter.state == 'no'">
              <v-icon class="red-icon ms-2 me-2">mdi-cancel</v-icon>
            </span>
          </span>
        </template> -->
        <template v-slot:item.showFile="{ item }">
          <v-btn
            small
            elevation="0"
            class="showFileBtn"
            @click="showFile(item.file_path)"
            v-if="item.file_path.length != 0"
            :disabled="showFileStatus"
          >
            <v-icon small class="me-1 ">mdi-file-pdf-box</v-icon> الملف المرفق
          </v-btn>
        </template>

        <template v-slot:item.showdata="{ item }">
          <v-btn
            elevation="0"
            small
            class="showDataBtn"
            @click="showdata(item.id)"
          >
            <v-icon small class="me-1 ">mdi-eye</v-icon>
            التفاصيل
          </v-btn>
        </template>
      </v-data-table>
      <!-- pagination -->
      <v-pagination
        v-model="currentGet"
        :length="lastGet"
        total-visible="6"
        color="#0f335f"
        elevation="0"
        square
        :disabled="disablePagination"
      ></v-pagination>

      <!-- pagination -->
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <NewDecisionDetails
      :newDecisionDetailsDialog="newDecisionDetailsDialog"
      :details="decisionInfo"
      @newDecisionDetailsDialogClosed="newDecisionDetailsDialog = $event"
    />

    <DecisionSearch
      :decisionSearchDialog="decisionSearchDialog"
      @decisionSearchDialogClosed="decisionSearchDialog = $event"
      @searchDateChanged="handleSearchByDate($event)"
    />
  </div>
</template>

<script>
import NewDecisionModal from "../../../components/Models/Decisions/AddingNewDecision";
import NewDecisionDetails from "../../../components/Models/Decisions/NewDecisionDetails";
import DecisionSearch from "../../../components/Models/AdvancedSearch";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "decisionPage",
  watch: {
    currentGet(newVal) {
      this.disablePagination = true;
      this.Info.page = newVal;
      this.fetchDesicionProject(this.Info)
        .then(() => {
          this.disablePagination = false;
        })
        .catch(() => {
          this.disablePagination = false;
        });
    },
  },
  created() {
    this.disablePagination = true;
    this.fetchDesicionProject(this.Info)
      .then(() => {
        this.disablePagination = false;
      })
      .catch(() => {
        this.disablePagination = false;
      });
  },
  computed: {
    ...mapGetters([
      "getAllDecisionProjects",
      "getDecisionProjectRecord",
      "getDecryptedFile",
    ]),

    currentGet: {
      get() {
        return this.$store.getters.getDecisionProjectCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setDecisionProjectCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getDecisionProjectLastPage;
      },
    },
  },
  components: {
    NewDecisionModal,
    NewDecisionDetails,
    DecisionSearch,
  },

  data() {
    return {
      showFileStatus: false,
      overlay: false,
      disablePagination: false,
      loadingSearch: false,
      Info: { page: 1, keyword: null, from: null, to: null, is_archived: 0 },

      headers: [
        { text: "ID", value: "id", width: "5%" },
        { text: "الموضوع", value: "subject", width: "15%" },
        { text: "التاريخ", value: "doc_date" },
        { text: "تفاصيل القرار", value: "details" },
        { text: "ملف القرار", value: "showFile", width: "10%" },
        { text: "التفاصيل", value: "showdata", width: "10%" },
      ],

      perPage: [10, 50, 100, 200, 300, 600, 1200],
      selectedPage: 1,
      newDecisionDetailsDialog: false,
      newDecisionDetailsId: 0,
      decisionInfo: {},
      decisionSearchDialog: false,
    };
  },

  methods: {
    ...mapActions(["fetchDesicionProject", "changeStatus", "decryptFile"]),
    itemRowBackground: function(item) {
      return item.seen == 0 ? "style-1" : "style-2";
    },

    searchMediaData() {
      this.Info.page = 1;
      this.disablePagination = true;
      this.fetchDesicionProject(this.Info)
        .then(() => {
          this.disablePagination = false;
        })
        .catch(() => {
          this.disablePagination = false;
        });
    },
    //click event
    showdata(id) {
      //  alert(id);
      this.decisionInfo = this.getDecisionProjectRecord(id);
      this.newDecisionDetailsDialog = true;
    },

    showFile(files) {
      this.showFileStatus = true;
      this.overlay = true;
      files.forEach((file) => {
        this.decryptFile(file)
          .then(() => {
            this.openBase64NewTab(this.getDecryptedFile);
            this.overlay = false;
            this.showFileStatus = false;
          })
          .catch(() => {
            this.overlay = false;
            this.showFileStatus = false;
          });
      });
    },

    showAllDecisionProject() {
      this.Info.page = 1;
      this.disablePagination = true;
      this.Info.from = null;
      this.Info.to = null;
      this.Info.keyword = null;
      this.fetchDesicionProject(this.Info)
        .then(() => {
          this.disablePagination = false;
        })
        .catch(() => {
          this.disablePagination = false;
        });
    },

    handleSearchByDate(event) {
      this.Info.from = event.from;
      this.Info.to = event.to;
      this.Info.keyword = null;

      if (this.selectedPage == 1) {
        this.Info.page = 1;
        this.disablePagination = true;
        this.fetchDesicionProject(this.Info)
          .then(() => {
            this.disablePagination = false;
          })
          .catch(() => {
            this.disablePagination = false;
          });
      }
    },
    showAdvancedSearchDialog() {
      this.decisionSearchDialog = true;
    },

    //numbering function
    padLeadingZeros(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
  },
};
</script>

<style lang="scss" scoped>
.green-icon {
  color: green;
}
.red-icon {
  color: red;
}
.username {
  background-color: transparent !important;
  color: #0f335f !important;
  border-radius: 15px;
  border: 1px solid #0f335f;
  padding-left: 6px;
  padding-top: 2px;
  padding-bottom: 3px;
  padding-right: 4px;
}

.showDataBtn {
  background-color: #0f335f !important;
  color: #f1f1f1 !important;
  border-radius: 15px;
  border: 1px solid #0f335f;
}
.showFileBtn {
  background-color: transparent !important;
  color: #0f335f !important;
  border-radius: 15px;
  border: 1px solid #0f335f;
  font-weight: 600;
}
.inboxes {
  direction: rtl;
  font-family: "JF Flat";
  font-size: large;
  overflow-x: hidden;
}
.style-1 {
  background: #adf6d0 !important;
  width: 100%;
  vertical-align: middle;
  height: 100%;
}
.style-2 {
  background: #fee !important;
  width: 100%;
  vertical-align: middle;
  height: 100%;
}
h2 {
  text-align: center;
}

.titleaddress {
  font-size: medium;
  color: black;
  margin: 0px;
  padding: 0;
  vertical-align: middle;
  height: 25px;
}

.custimized-pagination {
  background-color: transparent !important;
}
</style>
