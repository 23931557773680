<template>
  <div class="inboxes">
    <v-card class="pb-3 custom-data-table" elevation="0">
      <v-card-title>
        <v-row dense>
          <v-col cols="12" md="2" class="text-right">
            <NewMessageModal />
          </v-col>

          <v-col cols="12" md="6" class="pt-3 text-center">
            <v-text-field
              dense
              justify-center
              v-model="Info.keyword"
              label="بحث"
              single-line
              hide-details
              outlined
              class="small-text"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="4" s class="text-right pt-1">
            <v-btn @click="showAllInboxData()" icon color="blue">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn
              :loading="loadingSearch"
              :disabled="loadingSearch"
              dense
              elevation="0"
              color="#0f335f"
              class="small-text ma-2 white--text"
              @click="searchInboxData()"
            >
              <v-icon left dark>
                mdi-magnify
              </v-icon>
              بحث
            </v-btn>

            <v-btn
              class="small-text ma-2 outlined"
              elevation="0"
              @click="showAdvancedSearchDialog()"
            >
              <v-icon right dark class="mx-1">
                mdi-magnify-plus-outline
              </v-icon>
              بحث متقدم
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        align-center
        :headers="headers"
        :items="allInboxMail"
        hide-default-footer
        fixed-header
        dir="rtl"
      >
        <template v-slot:item.subject="{ item }">
          <div v-if="item.pivot.seen === 1">
            <v-icon small class="pa-1 seen-icon">mdi-check-circle</v-icon>
            <span>
              {{ item.subject }}
            </span>
          </div>
          <div v-if="item.pivot.seen === 0">
            <v-icon small class="pa-1 unseen-icon">mdi-email-outline</v-icon>
            <span>
              {{ item.subject }}
            </span>
          </div>
        </template>

        <template v-slot:item.directory="{ item }">
          <span class="sender ps-3 pe-3" v-if="item.emailsender">
            <span> {{ item.emailsender.name }}</span>
            <span v-if="item.emailsender.directory">
              / {{ item.emailsender.directory.name }}</span
            >
          </span>
        </template>
        <template v-slot:item.date="{ item }">
          <span class="eng-text ps-3 pe-3" v-if="item.emailsender">
            {{ item.date }}
          </span>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span class="eng-text ps-3 pe-3" v-if="item.emailsender">
            {{ item.created_at }}
          </span>
        </template>

        <template v-slot:item.showdata="{ item }">
          <v-btn
            small
            elevation="0"
            class="showFileBtn"
            v-if="item.emailfiles.length != 0"
            @click="showdata(item.emailfiles)"
            :disabled="showFileStatus"
          >
            <v-icon small class="me-1 ">mdi-file-pdf-box</v-icon> الملف المرفق
          </v-btn>

          <v-btn
            small
            elevation="0"
            class="showFileBtn"
            v-if="item.emailfiles.length == 0"
            @click="changeDataState(item.id)"
          >
            <v-icon small class="me-1 ">mdi-page-next-outline</v-icon> تم
            الاستلام
          </v-btn>
        </template>
      </v-data-table>
      <!-- pagination -->
      <v-pagination
        v-model="currentGet"
        :length="lastGet"
        total-visible="6"
        color="#0f335f"
        elevation="0"
        square
        :disabled="disablePagination"
      ></v-pagination>
      <!-- pagination -->
    </v-card>

    <DecisionSearch
      :decisionSearchDialog="decisionSearchDialog"
      @decisionSearchDialogClosed="decisionSearchDialog = $event"
      @searchDateChanged="handleSearchByDate($event)"
    />
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import NewMessageModal from "../../components/Models/Inbox/NewMessage";
import DecisionSearch from "../../components/Models/AdvancedSearch";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "InboxPage",
  watch: {
    currentGet(newVal) {
      this.disablePagination = true;
      this.Info.page = newVal;
      this.fetchInboxMails(this.Info)
        .then(() => {
          this.disablePagination = false;
        })
        .catch(() => {
          this.disablePagination = false;
        });
    },
  },
  created() {
    this.Info.page = 1;
    this.disablePagination = true;
    this.fetchInboxMails(this.Info)
      .then(() => {
        this.disablePagination = false;
      })
      .catch(() => {
        this.disablePagination = false;
      });
  },
  computed: {
    ...mapGetters(["allInboxMail", "getDecryptedFile"]),

    currentGet: {
      get() {
        return this.$store.getters.getInboxCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setInboxCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getInboxLastPage;
      },
    },
  },
  components: {
    NewMessageModal,
    DecisionSearch,
  },
  data() {
    return {
      showFileStatus: false,
      overlay: false,
      decisionDetailsId: 0,
      disablePagination: false,
      loadingSearch: false,
      Info: { page: 1, keyword: null, from: null, to: null },
      headers: [
        { text: "الموضوع", value: "subject" },
        { text: "العدد", value: "number" },
        { text: "التفاصيل", value: "body" },
        { text: "الجهة المرسلة", value: "directory" },
        { text: "التاريخ", value: "date" },
        { text: "تاريخ الارسال", value: "created_at" },
        { text: "الملاحظات", value: "notes" },
        { text: "عرض", value: "showdata" },
      ],
      perPage: [10, 50, 100, 200, 300, 600, 1200],
      selectedPage: 1,
      fileArray: [],
      decisionSearchDialog: false,
    };
  },

  methods: {
    ...mapActions([
      "fetchCounters",
      "fetchInboxMails",
      "changeStatus",
      "decryptFile",
    ]),
    handleSearchByDate(event) {
      this.Info.from = event.from;
      this.Info.to = event.to;
      this.Info.keyword = null;

      if (this.selectedPage == 1) {
        this.Info.page = 1;
        this.disablePagination = true;
        this.fetchInboxMails(this.Info)
          .then(() => {
            this.disablePagination = false;
          })
          .catch(() => {
            this.disablePagination = false;
          });
      }
    },
    itemRowBackground: function(item) {
      return item.pivot.seen == 0 ? "style-1" : "style-2";
    },

    showdata(files) {
      this.showFileStatus = true;
      this.overlay = true;
      files.forEach((file) => {
        this.decryptFile(file.path)
          .then(() => {
            this.changeStatus(file.email_id);
            this.openBase64NewTab(this.getDecryptedFile);
            this.overlay = false;
            this.showFileStatus = false;
          })
          .catch(() => {
            this.overlay = false;
            this.showFileStatus = false;
          });
      });
    },

    changeDataState(id) {
      this.changeStatus(id);
    },
    showAllInboxData() {
      this.Info.page = 1;
      this.disablePagination = true;
      this.Info.from = null;
      this.Info.to = null;
      this.Info.keyword = null;
      this.fetchInboxMails(this.Info)
        .then(() => {
          this.disablePagination = false;
        })
        .catch(() => {
          this.disablePagination = false;
        });
    },

    searchInboxData() {
      this.Info.page = 1;
      this.disablePagination = true;
      this.Info.from = null;
      this.Info.to = null;
      this.fetchInboxMails(this.Info)
        .then(() => {
          this.disablePagination = false;
        })
        .catch(() => {
          this.disablePagination = false;
        });
    },

    showAdvancedSearchDialog() {
      this.decisionSearchDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.inboxes {
  direction: rtl;
  font-family: "JF Flat";
  font-size: large;
  overflow-x: hidden;
}
.style-1 {
}
.style-2 {
}
h2 {
  text-align: center;
}

.titleaddress {
  font-size: medium;
  color: black;
  margin: 0px;
  padding: 0;
  vertical-align: middle;
  height: 25px;
}

.custimized-pagination {
  background-color: transparent !important;
}

.showFileBtn {
  background-color: transparent !important;
  color: #0f335f !important;
  border-radius: 15px;
  border: 1px solid #0f335f;
  font-weight: 600;
}
.seen-icon {
  color: #03a9f4 !important;
}

.sender {
  background-color: #f5f5f5 !important;
  border-radius: 15px;
  font-weight: normal;
}
</style>
