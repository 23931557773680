<template>
  <div >
  <!--Render this bar only when logged in-->
  <v-row class="custom-toolbar ps-4 pe-7 mb-1" dir="rtl">
    <v-col cols="12" lg="4" md="4" sm="12" class="pt-4 pb-1">
      <span class="normal-font small-text" color="#f1f1f1f1">
        <v-icon color="#f1f1f1f1" small>mdi-account-circle-outline</v-icon>
        <span class="ms-1 mt-1 eng-text white-text">{{
          authenticated.username + "@" + authenticated.role
        }}</span>
        <v-icon color="#f1f1f1f1">mdi-chevron-left</v-icon>
        <span class="me-1 ms-1">{{ path }}</span>
      </span>
    </v-col>

    <v-spacer v-show="$vuetify.breakpoint.mdAndUp"></v-spacer>
  </v-row>
</div>

</template>

<script>
import { mapGetters } from "vuex";

export default {
  watch: {},
  name: "firstPage",
  data() {
    return {
      pathVar: localStorage.getItem("current-path"),
      drawer: false,
      tab: null,
      fixedVar: true,
      path: "",
    };
  },
  created() {
    this.path = this.$cookies.get("link");
  },
  computed: {
    ...mapGetters(["authenticated"]),
  },

  mounted() {
    Event.$on("pathChanger", (newPath) => {
      this.path = newPath;
    });
  },
};
</script>

<style>

.custom-toolbar {
   background-color: #0f335f !important;
  color: #f1f1f1;
}
.normal-font {
  font-weight: normal !important;
}
</style>
