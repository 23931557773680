import axios from "@/axios";
import store from "../index";

const state = {
  DesicionProject: {},
  decisionViewers: {},
};

const getters = {
  DecisionPrefix: () =>
    store.getters["authenticated"].role == "publisher"
      ? "publisher"
      : store.getters["authenticated"].role == "admin"
      ? "admin"
      : "user",
  getAllDecisionProjects: (state) => state.DesicionProject.data,
  getAllDecisionViewers: (state) => state.decisionViewers,
  getDecisionProjectCurrentPage: (state) => state.DesicionProject.current_page,
  getDecisionProjectLastPage: (state) => state.DesicionProject.last_page,
  getDecisionProjectRecord: (state) => (id) => {
    var decisionRecord = state.DesicionProject.data.find(
      (decisionRecord) => decisionRecord.id === id
    );
    return decisionRecord;
  },
};
const actions = {
  async fetchDesicionProject({ commit }, info) {
    const response = await axios.get(
      this.getters["DecisionPrefix"] +
        "/documents?per_page=10&page=" +
        info.page +
        "&type=decision&certified=no&search=" +
        info.keyword +
        "&from=" +
        info.from +
        "&to=" +
        info.to +
        "&is_archived=" +
        info.is_archived
    );

    commit("SET_DESICION_PROJECTS", response.data);
  },

  async fetchDecisionViewers({ commit }) {
    const response = await axios.get(
      this.getters["DecisionPrefix"] + "/getEmailReceivers"
    );
    commit("SET_DECISION_VIEWERS", response.data);
  },

  async addNewDecision({ commit }, Document) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post("/publisher/documents", Document);
    commit("SET_DECISION_DATA", response.data);
  },

  async editDecision({ commit }, info) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post(
      "/publisher/documents/" + info.id,
      info.document
    );
    commit("SET_EDIT_DECISION_NEW_DATA", response.data.data);
  },

  async VotingDecision({ commit }, info) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post("user/documents/voute", info);
    commit("SET_EDIT_DECISION_DATA", response.data.data);
  },

  setDecisionProjectCurrentPage({ commit }, currentPage) {
    commit("SET_DECISION_PROJECTS_CURRENT_PAGE", currentPage);
  },
  setDecisionProjectLastPage({ commit }, lastPage) {
    commit("SET_DECISION_PROJECTS_LAST_PAGE", lastPage);
  },
};

const mutations = {
  SET_DECISION_DATA: (state, decision) => (state.DesicionProject = decision),

  SET_EDIT_DECISION_NEW_DATA: (state, payload) => {
    const i = state.DesicionProject.data
      .map((item) => item.id)
      .indexOf(payload.id);
    state.DesicionProject.data[i].details = payload.details;
    state.DesicionProject.data[i].subject = payload.subject;
    state.DesicionProject.data[i].file_path = payload.file_path;
    state.DesicionProject.data[i].is_certified = payload.is_certified;
    state.DesicionProject.data[i].document_receivers =
      payload.document_receivers;
    state.DesicionProject.data[i].document_vouters = payload.document_vouters;
    state.DesicionProject.data = state.DesicionProject.data.map((x) => {
      if (x.id === payload.id) {
        return Object.assign({}, x, payload.data);
      }
      return x;
    });
  },

  SET_DESICION_PROJECTS: (state, decision) =>
    (state.DesicionProject = decision),
  SET_DECISION_PROJECTS_CURRENT_PAGE: (state, data) =>
    (state.DesicionProject.current_page = data),
  SET_DECISION_PROJECTS_LAST_PAGE: (state, data) =>
    (state.DesicionProject.last_page = data),

  SET_DECISION_VIEWERS: (state, data) => (state.decisionViewers = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
