import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./scss/main.scss";
import VueCookies from "vue-cookies";

require("@/store/modules/subscriber");
Vue.config.productionTip = false;

Vue.prototype.$globalLink = "الصفحة الرئيسية";
Vue.use(VueCookies);
Vue.use(VueCookies, { expire: "7d" });
window.Event = new Vue();

Vue.mixin({
  methods: {
    base64toBlob(base64Data) {
      const sliceSize = 1024;
      const byteCharacters = atob(base64Data);
      const bytesLength = byteCharacters.length;
      const slicesCount = Math.ceil(bytesLength / sliceSize);
      const byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);
        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: "application/pdf" });
    },

    openBase64NewTab(base64Pdf) {
      var blob = this.base64toBlob(base64Pdf);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
      } else {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
      }
    },
  },
});

store.dispatch("attempt", localStorage.getItem("user_token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
