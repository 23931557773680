<template>
  <div id="composeMessageModal">
    <v-dialog
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="DecisionVotingModalDialog"
    >
      <v-card v-click-outside="handleClickOutside" dir="rtl">
        <v-card-title>
          <v-col class="text-center">
            <h5>
              <v-icon small>
                mdi-pencil-box-outline
              </v-icon>
              التصويت القرار
            </h5>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="votingForm" v-model="valid" lazy-validation>
              <v-row class="d-flex justify-center align-center">
                <v-col cols="12" lg="8" sm="12">
                  <v-textarea
                    outlined
                    label="التعليق على القرار"
                    v-model="comments"
                    class="small-text custom-textarea"
                    rows="2"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center align-center">
                <v-col cols="12" lg="3" sm="12">
                  <v-card-actions>
                    <v-btn
                      :loading="loadingSpinner"
                      :disabled="loadingSpinner"
                      class="send-btn ps-2 pe-4 small-text d-block"
                      @click="VotingOperation('agree')"
                      elevation="0"
                      ><v-icon small class="pe-1 ps-1 small-text"
                        >mdi-thumb-up-outline</v-icon
                      >
                      الموافقة على القرار
                    </v-btn>
                    <v-btn
                      :loading="loadingSpinner2"
                      :disabled="loadingSpinner2"
                      class="outlined ps-2 pe-4 small-text d-block"
                      @click="VotingOperation('notagree')"
                      elevation="0"
                      ><v-icon small class="pe-1 ps-1 small-text"
                        >mdi-thumb-down-outline</v-icon
                      >
                      رفض القرار
                    </v-btn>
                    <v-btn
                      color="darken-1"
                      class="outlined small-text d-block"
                      text
                      @click="closeDialog"
                      >إلغاء</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-row>

              <v-row dir="ltr" class="d-flex justify-center align-center">
                <v-col cols="12" lg="8" class="text-center">
                  <v-alert dense text type="success" v-if="VotingSucessAlert">
                    عملية التصويت على القرار <strong>تمت بنجاح</strong>
                  </v-alert>
                  <v-alert dense outlined type="error" v-if="VoutingErrorAlert">
                    فشل في عملية التصويت الرجاء اعادة المحاولة
                  </v-alert>
                </v-col>
              </v-row>

              <v-row dir="ltr" class="d-flex justify-center align-center">
                <v-col cols="12" lg="8" md="8" class="pt-0 text-left">
                  <v-card class="pa-4 text-left" elevation="0">
                    <v-row dir="ltr">
                      <v-col
                        cols="4"
                        sm="4"
                        class="pa-0 pt-2 ps-0 pe-0 text-center"
                        v-show="$vuetify.breakpoint.mdAndUp"
                      >
                        <img
                          style="vertical-align:middle;"
                          class="ps-1 pe-1 mt-1"
                          width="auto"
                          height="70px"
                          src="../../../assets/imgs/BlueCMC.svg"
                          alt
                        />
                        <img
                          style="vertical-align:middle;"
                          class="ps-1 pe-2 mt-1"
                          width="auto"
                          height="60px"
                          src="../../../assets/imgs/inlayer-design.svg"
                          alt
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        lg="8"
                        class=" text-left pe-0 pt-2 pt-3"
                      >
                        <span class="d-block eng-text blue-text"> </span>
                        <span class="d-block eng-text blue-text">
                          Electronic Mail System V.2.0
                        </span>
                        <span class="d-block eng-text blue-text comment-text">
                          Department of programming | IT Regulation directorate
                        </span>
                        <span class="d-block eng-text blue-text comment-text">
                          &copy; The Communication and Media Commission of Iraq
                          (CMC)
                        </span>
                        <span class="d-block eng-text blue-text comment-text">
                          <v-spacer></v-spacer>
                          Version:2.0 / Production
                          {{ new Date().getFullYear() }} / SYS-ID:85
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["DecisionVotingModalDialog", "details"],
  name: "VotingDecisionModal",
  computed: {},
  created() {},
  data() {
    return {
      loadingSpinner2:false,
      loadingSpinner: false,
      datePickerMenu: false,
      VotingSucessAlert: false,
      VoutingErrorAlert: false,
      alertText: null,
      snackbar: false,
      loader: null,
      loading3: false,
      valid: true,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      requiredRule2: [(v) => v.length > 0 || "هذا الحقل مطلوب"],
      Attachments: null,
      NewVoting: new FormData(),
      dialog: false,
      comments: null,
    };
  },

  methods: {
    ...mapActions(["VotingDecision"]),
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit("DecisionVotingModalDialogClosed", false);
    },
    textFormat(item) {
      if (item.name) {
        if (item.userdirectory) return item.name;
        else return item.name;
      }
    },

    VotingOperation(vote) {
      this.NewVoting = new FormData();
      if (!this.$refs.votingForm.validate()) return;
      if (vote == "agree") this.loadingSpinner = true;
      if (vote == "notagree") this.loadingSpinner2 = true;
      this.NewVoting.append("docId", this.details.id);
      this.NewVoting.append("type", vote);
      this.NewVoting.append("comment", this.comments);
      this.loader = "loading3";
      this.VotingDecision(this.NewVoting)
        .then(() => {
          this.loadingSpinner = false;
          this.loadingSpinner2 = false;
          this.VotingSucessAlert = true;
          setTimeout(() => {
            this.alertText = null;
            this.snackbar = false;
            this.loader = null;
            this.loading3 = false;
            this.valid = false;
            this.NewVoting = new FormData();
            this.VotingSucessAlert = false;
            this.VoutingErrorAlert = false;
            this.$refs.votingForm.reset();
            //this.closeDialog();
          }, 3000);
        })
        .catch(() => {
          this.loadingSpinner = false;
          this.VoutingErrorAlert = true;
          this.VotingSucessAlert = false;
          setTimeout(() => {
            this.VoutingErrorAlert = false;
          }, 3000);
        });
    },
  },
};
</script>

<style scoped>
* {
  font-weight: bold !important;
}

.send-btn {
  background-color: #0f335f !important;
  color: #f1f1f1 !important;
}

.textfields {
  max-height: 0px;
  vertical-align: middle;
}

.titleaddress {
  font-size: small;
  color: black;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  height: 25px;
}

h2 {
  text-align: center;
}

.outlined {
  background: transparent !important;
  border: 2px solid #0f335f;
}
</style>
