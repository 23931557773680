<template>
  <div>
    <v-app-bar
      elevation="0"
      class="align-center white-row"
      id="nav"
      :dir="direction"
    >
      <template>
        <v-app-bar-nav-icon
          @click="drawer = true"
          class="d-flex d-sm-none"
        ></v-app-bar-nav-icon>
        <img
          src="../assets/imgs/inlayer-design.svg"
          alt="forms-image"
          width="50px"
          height="auto"
          class="logoImag "
          v-show="$vuetify.breakpoint.mdAndUp"
        />

        <v-col cols="8" md="8" lg="3" sm="8">
          <h4
            class="normal-font  eng-text"
            v-show="$vuetify.breakpoint.mdAndUp"
          >
            Electronic Mail System <span class="eng-text" small> V.2.1</span>
          </h4>
          <h4 class="normal-font  eng-text" v-show="$vuetify.breakpoint.mobile">
            EMS | CMC Council | V.2.1
          </h4>
          <h6 v-show="$vuetify.breakpoint.mdAndUp" class="normal-font">
            مجلس المفوضين
          </h6>
        </v-col>

        <v-spacer v-show="$vuetify.breakpoint.mdAndUp"></v-spacer>
      </template>
    </v-app-bar>

    <!--Render this sidebar only when logged in-->
    <!-- Add a navigation bar -->
    <v-navigation-drawer
      dark
      class="drower"
      v-model="drawer"
      absolute
      temporary
    >
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="routeLink(item.route, item.linkName)"
          >
            <v-icon v-text="item.icon" class="white-text" small></v-icon>
            <v-list-item-content>
              <v-list-item-title class="ps-2 white-text">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  watch: {},
  name: "firstPage",
  data() {
    const lang = localStorage.getItem("lang") || "ar";
    return {
      pathVar: localStorage.getItem("current-path"),

      select:
        lang == "ar"
          ? { name: "Arabic", val: "ar" }
          : { name: "English", val: "en" },

      drawer: false,
      tab: null,
      fixedVar: true,
      time: "",
      date: "",
      path: "Home",
      direction: "rtl",
      week: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      timerID: setInterval(this.updateTime, 1000),
      items: [],
      role: "user",
    };
  },
  computed: {
    ...mapGetters(["authenticated"]),
  },
  created() {
    /* this.fullname = this.authenticated.fullname;*/
    this.role = this.authenticated.role;
    this.roleCheck(this.role);
  },

  methods: {
    ...mapActions(["signout"]),
    handleChange(e) {
      localStorage.setItem("lang", e);
      location.reload();
    },

    routeLink(route, linkName) {
      // console.log(route, linkName);
      if (route == "/") {
        this.signout();
      }

      this.$globalLink = linkName;
      this.$cookies.set("link", linkName); //return this
      Event.$emit("pathChanger", linkName);
      this.$router.push(route);
    },

    roleCheck(role) {
      if (role == "client" || role == "publisher") {
        this.items = [
          {
            title: "الصفحة الرئيسية",
            icon: "mdi-home-outline",
            route: "/user/dashboard/main",
            linkName: "الصفحة الرئيسية",
          },
          {
            title: "صندوق البريد",
            icon: "mdi-email-outline",
            route: "/user/dashboard/inbox",
            linkName: "صندوق البريد",
          },
          {
            title: "البريد المرسل",
            icon: "mdi-send-outline",
            route: "/user/dashboard/sent",
            linkName: "البريد المرسل",
          },
          {
            title: "القرارات وجدول الاعمال",
            icon: "mdi-chart-box-outline",
            route: "/user/dashboard/decisions/main-decision",
            linkName: "القرارات وجدول الأعمال",
          },
          {
            title: "ملفات للأطلاع",
            icon: "mdi-archive-outline",
            route: "/user/dashboard/notes/main-notes",
            linkName: "ملفات الأطلاع",
          },
          {
            title: "الاحصائات",
            icon: "mdi-chart-box-outline",
            route: "/user/dashboard/report",
            linkName: "الأحصائات",
          },

          {
            title: "خيارات المستخدم",
            icon: "mdi-account-circle-outline",
            route: "/user/dashboard/userinfo",
            linkName: "خيارات المستخدم",
          },

          {
            title: "تسجيل الخروج",
            icon: "mdi-logout",
            route: "/",
            linkName: "تسجيل الخروج",
          },
        ];
      } else {
        this.items = [];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.white-text {
  color: #f1f1f1 !important;
}
.drower {
  background-color: #0f335f !important;
}
.custom-toolbar {
  background-color: #0f335f !important;
  color: #f1f1f1 !important;
}
.normal-font {
  font-weight: normal !important;
}
#nav {
  background: #ffffff;
  -moz-box-shadow: none;
  -goog-ms-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btnStyle {
  background: #fff !important;
  border: none;
  box-shadow: none;
}

.btnStyle:hover {
  background: #fff !important;
  border: none;
  box-shadow: none;
}
.small-text input {
  font-size: 1.2em;
}

.circular {
  border-radius: 50%;
  border: 1px solid #37474f;
  color: #37474f !important;
}

.profile-button {
  //order: 1px solid #37474f;
  border: none !important;

  padding: 0.5em;
  padding-left: 0.7em;
  padding-right: 0.9em;
  margin-left: 0.9em;
  border: 1.3px solid #37474f;
  color: #37474f;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.red-text {
  color: #e53935;
}

.user-title {
  font-size: 14px !important;
  font-weight: normal !important;
}

.notification-button {
  border-radius: 10px;
  //border: 2px solid #e53935;
}
.notification-icon {
  color: #f1f1f1 !important;
}

.username-holder {
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
}
</style>
