<template>
  <div id="composeMessageModal">
    <v-dialog
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="DecisionEditModalDialog"
    >
      <v-card v-click-outside="handleClickOutside" dir="rtl">
        <v-card-title>
          <v-col class="text-center">
            <h6>
              <v-icon small>
                mdi-pencil-box-outline
              </v-icon>
              تعديل القرار
            </h6>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="editDecisionForm" v-model="valid" lazy-validation>
              <v-row class="d-flex justify-center align-center">
                <v-col cols="12" lg="8" md="8">
                  <v-row>
                    <v-col cols="12" md="6" class="text-right pt-0" dir="rtl">
                      <v-text-field
                        outlined
                        label="الموضوع"
                        hide-details="auto"
                        v-model="details.subject"
                        class="text-right small-text"
                        :rules="requiredRule"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="text-right pt-0" dir="rtl">
                      <v-menu
                        v-model="datePickerMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="details.doc_date"
                            label="التاريخ"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            required
                            outlined
                            dense
                            :rules="requiredRule"
                            class="small-text"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="details.doc_date"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-col cols="12" class="ps-0 pe-0 pb-0">
                    <v-textarea
                      outlined
                      label=" تفاصيل القرار"
                      value=""
                      v-model="details.details"
                      class="small-text custom-textarea"
                      rows="2"
                    ></v-textarea>
                  </v-col>

                  <v-col class="pa-0" cols="12" md="8">
                    <v-file-input
                      outlined
                      class="titleaddress small-text"
                      accept="application/pdf"
                      label="إرفاق الملفات"
                      show-size
                      dense
                      multiple
                      id="braodcast_attachment"
                      v-model="Attachments"
                    ></v-file-input>
                  </v-col>
                </v-col>
              </v-row>
              <v-row class="justify-center align-center mt-8">
                <v-col cols="12" md="6">
                  <v-alert
                    dense
                    text
                    type="success"
                    v-if="ComplaintSuccessAlert"
                  >
                    عملية تعديل القرار <strong>تمت بنجاح</strong>
                  </v-alert>
                  <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="ComplaintErrorAlert"
                  >
                    فشل في عملية التعديل الرجاء اعادة المحاولة
                  </v-alert>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center align-center">
                <v-col cols="12" lg="3" sm="12">
                  <v-card-actions>
                    <v-btn
                      :loading="loadingSpinner"
                      :disabled="loadingSpinner"
                      class="send-btn ps-2 pe-4 small-text d-block"
                      @click="ediDecisionFunction"
                      elevation="0"
                      ><v-icon small class="pe-1 ps-1 small-text"
                        >mdi-file-plus-outline</v-icon
                      >
                      تعديل القرار
                    </v-btn>
                    <v-btn
                      color="darken-1"
                      class="outlined small-text d-block"
                      text
                      @click="closeDialog"
                      >إلغاء</v-btn
                    >
                  </v-card-actions></v-col
                >
              </v-row>
              <v-row dir="ltr" class="d-flex justify-center align-center">
                <v-col cols="12" lg="8" md="8" class="pt-0 text-left">
                  <v-card class="pa-4 text-left" elevation="0">
                    <v-row dir="ltr">
                      <v-col
                        cols="4"
                        sm="4"
                        class="pa-0 pt-2 ps-0 pe-0 text-center"
                        v-show="$vuetify.breakpoint.mdAndUp"
                      >
                        <img
                          style="vertical-align:middle;"
                          class="ps-1 pe-1 mt-1"
                          width="auto"
                          height="70px"
                          src="../../../assets/imgs/BlueCMC.svg"
                          alt
                        />
                        <img
                          style="vertical-align:middle;"
                          class="ps-1 pe-2 mt-1"
                          width="auto"
                          height="60px"
                          src="../../../assets/imgs/inlayer-design.svg"
                          alt
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        lg="8"
                        class=" text-left pe-0 pt-2 pt-3"
                      >
                        <span class="d-block eng-text blue-text"> </span>
                        <span class="d-block eng-text blue-text">
                          Electronic Mail System V.2.0
                        </span>
                        <span class="d-block eng-text blue-text comment-text">
                          Department of programming | IT Regulation directorate
                        </span>
                        <span class="d-block eng-text blue-text comment-text">
                          &copy; The Communication and Media Commission of Iraq
                          (CMC)
                        </span>
                        <span class="d-block eng-text blue-text comment-text">
                          <v-spacer></v-spacer>
                          Version:2.0 / Production
                          {{ new Date().getFullYear() }} / SYS-ID:85
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["DecisionEditModalDialog", "details"],
  name: "editDecisionModal",
  computed: {
    ...mapGetters(["getDecisionProjectRecord"]),
  },
  created() {},
  data() {
    return {
      loadingSpinner: false,
      datePickerMenu: false,
      ComplaintSuccessAlert: false,
      ComplaintErrorAlert: false,
      alertText: null,
      snackbar: false,
      loader: null,
      loading3: false,
      valid: true,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      requiredRule2: [(v) => v.length > 0 || "هذا الحقل مطلوب"],
      Attachments: [],
      NewDecision: new FormData(),
      dialog: false,
      allSelected: false,
      info: {},
    };
  },

  methods: {
    ...mapActions(["editDecision"]),
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit("DecisionEditModalDialogClosed", false);
    },
    textFormat(item) {
      if (item.name) {
        if (item.userdirectory) return item.name;
        else return item.name;
      }
    },
    ediDecisionFunction() {
      this.NewDecision = new FormData();
      if (!this.$refs.editDecisionForm.validate()) return;
      this.loadingSpinner = true;

      this.NewDecision.append("subject", this.details.subject);
      this.NewDecision.append("docDate", this.details.doc_date);
      this.NewDecision.append("details", this.details.details);
      if (this.Attachments) {
        for (let i = 0; i < this.Attachments.length; i++) {
          this.NewDecision.append("file[" + i + "]", this.Attachments[i]);
        }
      }

      this.NewDecision.append("isCertified", "no");
      this.NewDecision.append("_method", "put");
      this.loader = "loading3";

      this.info.document = this.NewDecision;
      this.info.id = this.details.id;
      this.editDecision(this.info)
        .then(() => {
          this.loadingSpinner = false;
          this.ComplaintSuccessAlert = true;

          setTimeout(() => {
            //this.$emit("DecisionEditModalDialogClosed", false);
            this.alertText = null;
            this.snackbar = false;
            this.loader = null;
            this.loading3 = false;
            this.valid = false;
            this.NewDecision = new FormData();
            this.ComplaintSuccessAlert = false;
            this.ComplaintErrorAlert = false;
            //this.$refs.editDecisionForm.reset();
          }, 3000);
        })
        .catch(() => {
          this.loadingSpinner = false;
          this.ComplaintErrorAlert = true;
          this.ComplaintSuccessAlert = false;
          setTimeout(() => {
            this.ComplaintErrorAlert = false;
          }, 3000);
        });
    },
  },
};
</script>

<style scoped>
* {
  font-weight: bold !important;
}

.send-btn {
  background-color: #0f335f !important;
  color: #f1f1f1 !important;
}

.textfields {
  max-height: 0px;
  vertical-align: middle;
}

.titleaddress {
  font-size: small;
  color: black;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  height: 25px;
}

h2 {
  text-align: center;
}

.outlined {
  background: transparent !important;
  border: 2px solid #0f335f;
}
</style>
