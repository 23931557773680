//here are the routes of the vuex javascript
import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import inbox from "./modules/inbox";
import directories from "./modules/directories";

import counter from "./modules/counter";
import department from "./modules/department";
import sent_mail from "./modules/sent_mail";
import user from "./modules/user";
import decision from "./modules/decisions";
import decision_projects from "./modules/decision_projects";
import information from "./modules/information";
import agenda from "./modules/agenda";
import VueQRCodeComponent from "vue-qrcode-component";
import fetch_data from "./modules/fetch_data";

Vue.component("qr-code", VueQRCodeComponent);
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  actions: {},
  mutations: {},
  modules: {
    auth,
    inbox,
    directories,
    counter,
    department,
    sent_mail,
    user,
    decision,
    decision_projects,
    information,
    agenda,
    fetch_data,
  },
});
