<template>
  <div>
    <v-row dir="rtl">
      <v-col
        cols="12"
        md="3"
        v-for="(card, index) in statisticsCards"
        :key="index"
      >
        <v-card
          elevation="0"
          class="pt-0 pb-0 hoverable-card"
          @click="$router.push(card.link)"
        >
          <v-col cols="12" md="12" lg="12" sm="12" class="pt-0 pb-0 ">
            <v-card-text class="pt-0 pb-0  text-center">
              <v-img
                :src="require('../../assets/imgs/' + card.img)"
                height="auto"
                width="100%"
                contain
                class="text-center ps-8 pe-8"
              />
            </v-card-text>
            <v-divider></v-divider>
          </v-col>

          <v-card-subtitle class="pt-1 subtitle-custom pb-4 text-center">
            <a @click="$router.push(card.link)">
              {{ card.link_title }}
            </a></v-card-subtitle
          >
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "mainPage",

  data() {
    return {
      search: "",
      iconsColor: "#4fce82",
      statisticsCards: [
        {
          img: "mailbox_final.svg",
          icon: "mdi-email-receive",
          title: "البريد المستلم",
          countName: "receivedMail",
          link: "/user/dashboard/inbox",
          link_title: " صندوق البريد",
        },

        {
          img: "sent_mail.svg",
          icon: "mdi-email-send",
          title: "عدد البريد المرسل",
          countName: "sentMail",
          link: "/user/dashboard/sent",
          link_title: "  البريد المرسل",
        },

        {
          img: "decisions.svg",
          icon: "mdi-email-send",
          title: "القرارات وجدول الاعمال",
          countName: "decisions",
          link: "/user/dashboard/decisions",
          link_title: " المقررات",
        },

        {
          img: "personal_gray.svg",
          icon: "mdi-email-send",
          title: "ملفات للطلاع",
          countName: "sentMail",
          link: "/user/dashboard/notes",
          link_title: " ملفات للأطلاع",
        },

        {
          img: "folder_gray.svg",
          icon: "mdi-email-send",
          title: "خيارات المستخدم",
          countName: "sentMail",
          link: "/user/dashboard/userinfo",
          link_title: " خيارات المستخدم",
        },
      ],
    };
  },
};
</script>
