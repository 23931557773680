import axios from "@/axios";
const state = {
  documentInfo: {},
};
const getters = {
  informationGetter: (state) => state.documentInfo.data,
};
const actions = {
  async fetchDocumentInfoUUID({ commit }, uuid) {
    const response = await axios.post(
      "/documents/showDocumentUsingQrCode?uuid=" + uuid
    );
    if (response.status == 200) {
      commit("SET_DOCUMENT_INFO_MUTATION", response);
    }
  },
};
const mutations = {
  SET_DOCUMENT_INFO_MUTATION: (state, data) => (state.documentInfo = data),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
