<template>
  <div>
    <v-dialog
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :value="newDecisionDetailsDialog"
    >
      <v-card v-click-outside="handleClickOutside" dir="rtl">
        <v-container class="pt-5">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 ps-2 pe-2">
              <v-btn
                color="darken-1"
                class="outlined small-text mt-2  ms-1 me-1 "
                text
                @click="closeDialog"
              >
                <v-icon small class="ms-1 me-1"
                  >mdi-arrow-right-drop-circle-outline</v-icon
                >
                رجوع</v-btn
              >
              <v-btn
                class="send-btn  mt-2  small-text ms-1 me-1"
                text
                v-if="authenticated.role == 'client'"
                @click="votingFunction"
                ><v-icon small>mdi-check-circle-outline</v-icon>
                <span class="me-1 ms-1">
                  التصويت على القرار
                </span></v-btn
              >
              <v-btn
                v-if="authenticated.role == 'publisher'"
                @click="editDialog"
                class="outlined mt-2  small-text ms-1 me-1"
                text
                ><v-icon small>mdi-file-edit-outline</v-icon>
                <span class="me-1 ms-1">تعديل</span></v-btn
              >

              <v-btn
                v-if="authenticated.role == 'publisher'"
                class="outlined mt-2  small-text ms-1 me-1"
                text
                @click="certificateFunction"
                ><v-icon small>mdi-state-machine</v-icon>
                <span class="me-1 ms-1">تعديل حالة القرار</span></v-btn
              >
            </v-col>

            <v-col cols="12" sm="12" md="8" lg="8">
              <v-col cols="12"
                ><span class=" me-1 ms-1">
                  تفاصيل القرار
                </span></v-col
              >

              <!-- decision details -->
              <v-col cols="12" sm="12" md="12" lg="12" class="ps-0 pe-0">
                <div class="custom-division pa-4">
                  <span class="mt-2 pb-2  d-block small-text">الموضوع</span>
                  <span class=" d-block small-text  ">
                    {{ this.details.subject }}
                  </span>
                </div>
                <div class="custom-division-2 pa-4">
                  <span class="mt-2 pb-2 d-block small-text">التاريخ</span>

                  <span class="eng-text d-block small-text">
                    {{ this.details.doc_date }}
                  </span>
                </div>

                <div class="custom-division pa-4">
                  <span class="mt-2 pb-2 d-block small-text"
                    >تفاصيل القرار</span
                  >

                  <span class=" d-block small-text ">
                    {{ this.details.details }}
                  </span>
                </div>
              </v-col>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="file-section">
              <div class=" pa-4" v-if="details.file_path.length != 0">
                <span class=" d-block small-text ">
                  الملف المرفق
                </span>

                <span class=" d-block small-text ">
                  يرجى تحميل الملف المرفق لكي يتم استعراض تفاصيل الملف
                </span>

                <v-btn
                  :loading="loadingSpinner"
                  :disabled="showFileStatus"
                  class="send-btn ps-2 pe-4 mt-3 small-text"
                  @click="showFile(details.file_path)"
                  elevation="0"
                  ><v-icon small class="pe-1 ps-1">mdi-file-pdf-box</v-icon>
                  تحميل ملف القرار
                </v-btn>
              </div>
              <div class=" pa-4 pt-0">
                <span class=" d-block small-text">
                  المصوتون
                </span>
                <span class=" d-block small-text ">
                  أعضاء مجلس المفوضين الذين صوتوا على القرار
                </span>

                <div
                  v-for="(item, i) in this.details.document_vouters"
                  :key="i"
                >
                  <v-alert
                    :color="checkColorParameter(item.type).color"
                    text
                    class="small-text pa-3 mt-1 mb-1"
                  >
                    <v-row>
                      <v-col cols="1" md="1" lg="1" sm="1">
                        <v-icon :color="checkColorParameter(item.type).color">{{
                          checkColorParameter(item.type).icon
                        }}</v-icon>
                      </v-col>
                      <v-col cols="11" md="11" lg="11" sm="11">
                        <span class="me-1 ms-1 d-block">
                          {{ item.user_info.name }}
                        </span>
                        <span
                          class="me-1 ms-1 d-block"
                          v-if="item.comment != null"
                        >
                          {{ item.comment }}
                        </span>
                        <h4 class="me-1 ms-1 d-block"></h4>
                      </v-col>
                    </v-row>
                  </v-alert>
                </div>
              </div>
            </v-col>
          </v-row>

          <!-- File details -->
        </v-container>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>

    <DecisionEditModal
      :DecisionEditModalDialog="DecisionEditModalDialog"
      :details="decisionInfo"
      @DecisionEditModalDialogClosed="DecisionEditModalDialog = $event"
    />

    <DecisionVotingModal
      :DecisionVotingModalDialog="DecisionVotingModalDialog"
      :details="decisionInfo"
      @DecisionVotingModalDialogClosed="DecisionVotingModalDialog = $event"
    />

    <DecisionCertificateModal
      :DecisionCertificateModalDialog="DecisionCertificateModalDialog"
      :details="decisionInfo"
      @DecisionCertificateModalDialogClosed="
        DecisionCertificateModalDialog = $event
      "
    />
  </div>
</template>
<script>
import DecisionEditModal from "../../../components/Models/Decisions/EditDecision";
import DecisionVotingModal from "../../../components/Models/Decisions/Voting";
import DecisionCertificateModal from "../../../components/Models/Decisions/CertifiedDecision";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["newDecisionDetailsDialog", "details"],
  data() {
    return {
      overlay: false,
      showFileStatus: false,
      colorParameter: "",
      loadingSpinner: false,
      DecisionEditModalDialog: false,
      DecisionVotingModalDialog: false,
      DecisionCertificateModalDialog: false,
      decisionInfo: {},
    };
  },
  components: {
    DecisionEditModal,
    DecisionVotingModal,
    DecisionCertificateModal,
  },

  computed: {
    ...mapGetters(["authenticated", "getDecryptedFile"]),
  },
  created() {},
  methods: {
    ...mapActions(["decryptFile"]),
    showFile(files) {
      this.showFileStatus = true;
      this.overlay = true;
      files.forEach((file) => {
        this.decryptFile(file)
          .then(() => {
            this.openBase64NewTab(this.getDecryptedFile);
            this.overlay = false;
            this.showFileStatus = false;
          })
          .catch(() => {
            this.overlay = false;
            this.showFileStatus = false;
          });
      });
    },
    checkColorParameter(state) {
      var object = {
        color: "",
        icon: "",
      };
      if (state == "agree") {
        object.color = "green";
        object.icon = "mdi-check-circle";
        return object;
      }
      if (state == "notagree") {
        object.color = "deep-orange";
        object.icon = "mdi-information-outline";
        return object;
      }
    },
    votingFunction() {
      this.decisionInfo = this.details;
      this.DecisionVotingModalDialog = true;
    },
    certificateFunction() {
      this.decisionInfo = this.details;
      this.DecisionCertificateModalDialog = true;
    },

    editDialog() {
      this.decisionInfo = this.details;
      this.DecisionEditModalDialog = true;
    },

    closeDialog() {
      this.$emit("newDecisionDetailsDialogClosed", false);
    },
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") {
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped>
.custom-division {
  background: #f5f5f5 !important;
  color: #0f335f;
  border-radius: 2px;
  border: 1px solid #f5f5f5 !important;
}

.custom-division-2 {
  background: #eeeeee !important;
  color: #0f335f;
  border-radius: 2px;
  border: 1px solid #eeeeee !important;
}
.custom-division-3 {
  background: #fafafa !important;
  color: #0f335f;
  border-radius: 2px;
  border: 1px solid #cfd8dc !important;
}

.send-btn {
  background-color: #0f335f !important;
  color: #f1f1f1 !important;
}

.outlined {
  background: transparent !important;
  border: 1px solid #0f335f;
  color: #0f335f !important;
}
</style>
